import React from "react";

const teamGalleryIcon = ({fill, size = "22"}) => {
  return (
    <svg 
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg" 
      fill="none"
      viewBox="0 0 22 22">
      <defs>
        <clipPath id="clip-path">
          <rect className="cls-1" width="22" height="22"/>
        </clipPath>
      </defs>
      <g className="cls-2">
        <path className="cls-3" d="M12,8.7a2.49,2.49,0,0,0,1.41-.43,2.56,2.56,0,0,0,.94-1.14,2.51,2.51,0,0,0,.14-1.46,2.51,2.51,0,0,0-2-2,2.56,2.56,0,0,0-1.47.14,2.52,2.52,0,0,0-1.13.94,2.49,2.49,0,0,0-.43,1.4A2.53,2.53,0,0,0,12,8.7Zm0-3.62a1.09,1.09,0,0,1,.61.18,1.07,1.07,0,0,1,.4.49,1.12,1.12,0,0,1,.06.63,1.11,1.11,0,0,1-.85.85,1.09,1.09,0,0,1-1.12-.46,1.09,1.09,0,0,1-.18-.61A1.09,1.09,0,0,1,12,5.08Z"
          fill={fill}
        />
        <path className="cls-3" d="M17.19,5.14a3.41,3.41,0,0,0-3.42-3.42H4.48A3.41,3.41,0,0,0,1.06,5.14v9.29a3.41,3.41,0,0,0,3.42,3.42h6.59a.71.71,0,0,0,.71-.71V17a.71.71,0,0,0-.71-.71H4.48a1.89,1.89,0,0,1-1.89-1.9v-1L5.8,10.21a2,2,0,0,1,.61-.41,1.92,1.92,0,0,1,1.45,0,2,2,0,0,1,.61.41l.46.46.6.6,1.37,1.41a.75.75,0,0,0,1.1-1l-.75-.82L10.11,9.69l-.56-.56a3.32,3.32,0,0,0-1.11-.74,3.47,3.47,0,0,0-2.61,0,3.32,3.32,0,0,0-1.11.74L2.59,11.26V5.14A1.88,1.88,0,0,1,4.48,3.25h9.29a1.89,1.89,0,0,1,1.9,1.89V7.6a.76.76,0,1,0,1.52,0V7.21h0Z"
          fill={fill}
        />
      </g>
        <path className="cls-4" d="M16.48,15.06a2.9,2.9,0,0,0,1.61-.49,3,3,0,0,0,1.07-1.31,2.89,2.89,0,0,0,.16-1.67,2.92,2.92,0,0,0-.79-1.49A2.89,2.89,0,0,0,17,9.31a2.85,2.85,0,0,0-1.68.16,2.9,2.9,0,0,0-.94,4.73A3,3,0,0,0,16.48,15.06Zm0-4.84a1.91,1.91,0,0,1,1.78,1.19,1.94,1.94,0,0,1-2.53,2.53,1.92,1.92,0,0,1-.86-.71,1.88,1.88,0,0,1-.33-1.08,1.93,1.93,0,0,1,1.94-1.93Z"
          fill={fill}
        />
        <path className="cls-5" d="M13.45,17.35a4.28,4.28,0,0,1,7.31,3,.41.41,0,0,1-.41.41.41.41,0,0,1-.3-.12.4.4,0,0,1-.12-.29,3.46,3.46,0,1,0-6.91,0,.41.41,0,0,1-.41.41.41.41,0,0,1-.3-.12.4.4,0,0,1-.12-.29A4.25,4.25,0,0,1,13.45,17.35Z"
          fill={fill}
        />
      </svg>
  );
};

export default teamGalleryIcon;
