/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import "./FAQ.scss";
import arrowup from "../../assets/images/icons/faq-arrow.png";
import arrowdown from "../../assets/images/icons/faq-arrow1.png";
import loader from "../../assets/images/workspace/upscale-gif.gif";
import SideNavComponent from "../../components/SideNav/SideNavComponent";
import { Tabs, Tab, TabList, TabPanel } from "react-tabs";
import { GetAllFAQs } from "../../utils/apiCalls";
import { UserSocketContext } from "../../store/UserSocketData";
import { TeamDesignContext } from "../../store/TeamDesignData";
import UserPortalLayout from "../../components/UserPortalLayout/UserPortalLayout";
import { useTranslation } from "react-i18next";

const FAQ = () => {
  const { t } = useTranslation();
  const { updateSocketData } = useContext(UserSocketContext);
  const { isTeamChanges } = useContext(TeamDesignContext);
  const faqApiRef = useRef(false);

  const [openPanels, setOpenPanels] = useState({});
  const [isAllFAQs, setIsAllFAQs] = useState([]);
  const [loading, setLoading] = useState(false);

  const handlePanelToggle = (panelId) => {
    setOpenPanels((prevOpenPanels) => {
      const newState = {
        ...prevOpenPanels,
        [panelId]: !prevOpenPanels[panelId],
      };
      return newState;
    });
  };

  const ExpansionPanel = ({ panelId, header, children }) => (
    <div className="expand-panel">
      <div
        className="expand-panel__heading"
        onClick={() => handlePanelToggle(panelId)}
      >
        <h4>{header}</h4>
        {openPanels[panelId] ? (
          <img src={arrowdown} alt="arrow" />
        ) : (
          <img src={arrowup} alt="arrow" />
        )}
      </div>
      {openPanels[panelId] && (
        <div className="expand-panel__content">{children}</div>
      )}
    </div>
  );

  useEffect(() => {
    if (!faqApiRef.current) {
      faqApiRef.current = true;
      getAllFaqs();
    }
  }, []);

  useEffect(() => {
    getAllFaqs();
  }, [isTeamChanges]);

  const getAllFaqs = () => {
    setLoading(true);
    const lang = localStorage.getItem("language");
    const chinese = lang === "ch" ? true : false;
    GetAllFAQs((res) => {
      if (res?.message === "Invalid access token") {
        updateSocketData({
          isExitPopup: true,
        });
      }
      if (res.status === 200) {
        setIsAllFAQs(res.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }, chinese);
  };

  return (
    <UserPortalLayout>
      <div className="faq-airi">
        <SideNavComponent />
        <div className="faq-airi__container">
          <div className="faq-airi__sections" data-scrollable="true">
            {isAllFAQs?.length !== 0 ? (
              <Tabs>
                <TabList>
                  {isAllFAQs?.length !== 0 &&
                    isAllFAQs?.map((faq, index) => {
                      return <Tab key={index}>{faq?.heading}</Tab>;
                    })}
                </TabList>
                {isAllFAQs?.length !== 0 &&
                  isAllFAQs?.map((faq, outerIndex) => {
                    return (
                      <TabPanel key={outerIndex}>
                        {faq?.content?.map((point, innerIndex) => {
                          return (
                            <div
                              className="faq-airi__sections__expantion"
                              key={innerIndex}
                            >
                              <div className="faq-airi__sections__expantion__heading">
                                <h3>{point?.subheading}</h3>
                              </div>
                              {point?.subContent?.map((points, subIndex) => {
                                const formattedMessage = points?.answer
                                  ? points?.answer
                                      ?.trim()
                                      ?.replace(/\\n/g, "<br>")
                                  : "";
                                const panelId = `faq-panel-${outerIndex + 1}-${
                                  innerIndex + 1
                                }-${subIndex + 1}`;
                                return (
                                  <div
                                    className="faq-airi__sections__expantion__content"
                                    key={subIndex}
                                  >
                                    <ExpansionPanel
                                      panelId={panelId}
                                      header={points.question}
                                    >
                                      <h5
                                        dangerouslySetInnerHTML={{
                                          __html: formattedMessage,
                                        }}
                                      ></h5>
                                    </ExpansionPanel>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </TabPanel>
                    );
                  })}
              </Tabs>
            ) : (
              <div className="gif">
                {loading ? (
                  <img src={loader} alt="gif-loader" />
                ) : (
                  <p>{t("no_data_found")}</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </UserPortalLayout>
  );
};

export default FAQ;
