import React, { useContext } from "react";
import "./WorkspaceFeedRefPopup.scss";
import upscale from "../../../assets/images/workspace/icons/feed-upscale.png";
import base from "../../../assets/images/workspace/icons/feed-base.png";
import download from "../../../assets/images/workspace/icons/feed-download.png";
import cross from "../../../assets/images/workspace/icons/cross-icon.png";
import referenceicon from "../../../assets/images/workspace/icons/reference.png";
import { ResponsiveDataContext } from "../../../store/ResponsiveData";
import TooltipRight from "../../Tooltips/TooltipRight";
import { useTranslation } from "react-i18next";
import enhancei from "../../../assets/images/workspace/icons/enhance_feed_popup.png";

const WorkspaceFeedRefPopup = ({
  onCloseClick,
  handlePopupclose,
  popupData,
  showAllIcons,
  activeIndex,
  isLoader,
  onUpdateBaseImage,
  onUpdateRefrenceImage,
  onUpscaleBaseImage,
  onDownloadClick,
  projectMedias,
}) => {
  const { t } = useTranslation();
  const { responsiveData } = useContext(ResponsiveDataContext);
  const lang = localStorage.getItem("language");

  const handleCrossClick = () => {
    onCloseClick();
  };

  const handleDownloadClick = (activeImageIndex) => {
    onDownloadClick(activeImageIndex, false);
  };

  const handleUpdateBaseImage = (activeImageIndex) => {
    onUpdateBaseImage(activeImageIndex, false);
  };

  const handleUpdateRefrenceImage = (activeImageIndex) => {
    onUpdateRefrenceImage(activeImageIndex, 11, false);
  };

  const handleUpscaleClick = (activeImageIndex) => {
    onUpscaleBaseImage(activeImageIndex, false, true);
  };

  //const isUpscale = projectMedias[0]?.toolset?.includes("upscale");

  return (
    <div className="feed-ref" onClick={handlePopupclose}>
      <div className="feed-ref__container">
        <div
          className="feed__popup__section__heading__container"
          onClick={(e) => e.stopPropagation()}
        >
          {popupData === undefined ? (
            <div className="feed__popup__section__heading__icons">
              <div
                className="upscale-icon tooltip-top image-icon"
                style={{ position: "relative" }}
              >
                <img
                  src={referenceicon}
                  alt="reference-iocn"
                  onClick={() => handleUpdateRefrenceImage(activeIndex)}
                />
                <div className="tooltip-show">
                  {!responsiveData && (
                    <TooltipRight
                      TooltipContent={t("send_to_reference_image")}
                      display={{
                        width: `${lang === "ch" ? "136px" : "198px"}`,
                        left: `${lang === "ch" ? "-136px" : "-198px"}`,
                      }}
                    />
                  )}
                </div>
              </div>
              <div
                className="upscale-icon tooltip-top image-icon"
                style={{ position: "relative" }}
              >
                <img
                  src={base}
                  alt="base-iocn"
                  onClick={() => handleUpdateBaseImage(activeIndex)}
                />
                <div className="tooltip-show">
                  {!responsiveData && (
                    <TooltipRight
                      TooltipContent={t("send_to_image_toolset")}
                      display={{
                        width: `${lang === "ch" ? "150px" : "168px"}`,
                        left: `${lang === "ch" ? "-148px" : "-174px"}`,
                      }}
                    />
                  )}
                </div>
              </div>
              <div
                className="upscale-icon tooltip-top"
                style={{ position: "relative" }}
              >
                {/*isUpscale ? (
                  <>
                    <img
                      src={upscale}
                      alt="upscale-iocn"
                      className="disabled"
                    />
                    <div className="tooltip-show">
                      {!responsiveData && (
                        <TooltipRight
                          TooltipContent={t("already_upscaled")}
                          display={{
                            width: `${lang === "ch" ? "156px" : "240px"}`,
                            left: `${lang === "ch" ? "-162px" : "-246px"}`,
                          }}
                        />
                      )}
                    </div>
                  </>
                ) : (*/
                  <>
                    <img
                      className={` ${isLoader ? "active-load" : ""}`}
                      src={upscale}
                      alt="enhance-iocn"
                      onClick={() => handleUpscaleClick(activeIndex)}
                    />
                    <div className="tooltip-show">
                      {!responsiveData && (
                        <TooltipRight
                          TooltipContent={t("send_enhance")}
                          display={{
                            width: `${lang === "ch" ? "112px" : "220px"}`,
                            left: `${lang === "ch" ? "-112px" : "-226px"}`,
                          }}
                        />
                      )}
                    </div>
                  </>
                //)
                }
              </div>
              <div
                className="download-icon tooltip-top"
                style={{ position: "relative" }}
              >
                <img
                  src={download}
                  alt="download-iocn"
                  onClick={() => handleDownloadClick(activeIndex)}
                />
                <div className="tooltip-show">
                  {!responsiveData && (
                    <TooltipRight
                      TooltipContent={t("download")}
                      display={{
                        width: `${lang === "ch" ? "66px" : "102px"}`,
                        left: `${lang === "ch" ? "-66px" : "-108px"}`,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <>
              {showAllIcons === true && (
                <div className="feed__popup__section__heading__icons">
                  <div
                    className="download-icon tooltip-top"
                    style={{ position: "relative" }}
                  >
                    <img
                      src={download}
                      alt="download-iocn"
                      onClick={() => handleDownloadClick(activeIndex)}
                    />
                    <div className="tooltip-show">
                      {!responsiveData && (
                        <TooltipRight
                          TooltipContent={t("download")}
                          display={{
                            width: `${lang === "ch" ? "60px" : "102px"}`,
                            left: `${lang === "ch" ? "-66px" : "-108px"}`,
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          <div
            className="feed__popup__section__heading__close"
            onClick={
              popupData !== undefined ? handlePopupclose : handleCrossClick
            }
          >
            <img
              src={cross}
              alt="cross-iocn"
              onClick={
                popupData !== undefined ? handlePopupclose : handleCrossClick
              }
            />
          </div>
        </div>
        <div className="feed-ref__section" onClick={(e) => e.stopPropagation()}>
          <img
            src={
              popupData === undefined
                ? projectMedias[activeIndex]?.referenceImage[0]?.url
                : popupData?.referenceImage[0]?.url
            }
            alt="ref-img"
          />
        </div>
      </div>
    </div>
  );
};

export default WorkspaceFeedRefPopup;
