/* eslint-disable no-unused-vars */
import { t } from "i18next";
import {
  fetchAdminCall,
  fetchAdminLoginCall,
  fetchCall,
  fetchLoginCall,
} from "./ajax";
import { API_CONSTANTS, API_METHODS } from "./api-constant";

export let apiError = false;

const handleNetworkError = () => {
  // const confirmed = window.confirm(t("api_error_message"));
  // if (confirmed) {
  //   window.location.reload();
  // } else {
  //   window.location.reload();
  // }
  return null;
};

export const formatTeamNames = (teams, max=10) => {
  if(teams){
    const teamList = teams.split(', ');
    return teamList.map(team => {
        return team.length > max ? team.slice(0, max) + '...' : team;
    }).join(', ');
  }else return ""
};

export const UserLogin = async (callback, payload) => {
  const url = `${API_CONSTANTS.LOGIN}`;
  return fetchCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const getRefreshToken = async (callback, payload) => {
  const url = `${API_CONSTANTS.GET_REFRESH_TOKEN}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetCurrentUserDetails = async (callback, payload) => {
  const url = `${API_CONSTANTS.GET_CURRENT_USER}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET,
    payload
  ).catch(handleNetworkError);
};

export const ChangeProfilePicture = async (callback, payload) => {
  const url = `${API_CONSTANTS.CHANGE_PROFILE_PICTURE}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const EditCurrentUserDetails = async (callback, payload) => {
  const url = `${API_CONSTANTS.EDIT_CURRENT_USER_DETAILS}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetAllTutorials = async (callback, payload) => {
  const url = `${API_CONSTANTS.GET_ALL_TUTORIALS}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET,
    payload
  ).catch(handleNetworkError);
};

export const GetPhoneLengthCountry = async (callback, payload) => {
  const url = `${API_CONSTANTS.GET_PHONE_NUMBER_LENGTH_WITH_COUNTRY}`;
  return fetchCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetAllNewsWithPagination = async (
  callback,
  payload,
  language,
  tab
) => {
  const queryString = `?pageIndex=${payload.pageIndex}&pageSize=${payload.pageSize}&chinese=${language}&tab=${tab}`;
  const url = `${API_CONSTANTS.GET_ALL_NEWS_WITH_PAGINATION + queryString}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GenerateWorkFlowText = async (callback, payload) => {
  const url = `${API_CONSTANTS.GENERATE_WORK_FLOW_TEXT}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GenerateWorkFlowDesign = async (callback, payload) => {
  const url = `${API_CONSTANTS.GENERATE_WORK_FLOW_DESIGN}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GenerateWorkFlowImage = async (callback, payload) => {
  const url = `${API_CONSTANTS.GENERATE_WORK_FLOW_IMAGE}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GenerateWorkFlowStylize = async (callback, payload) => {
  const url = `${API_CONSTANTS.GENERATE_WORK_FLOW_STYLIZE}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GenerateWorkFlowUpscale = async (callback, payload) => {
  const url = `${API_CONSTANTS.GENERATE_WORK_FLOW_UPSCALE}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GenerateWorkFlowUpscaleFeed = async (callback, payload) => {
  const url = `${API_CONSTANTS.GENERATE_WORK_FLOW_UPSCALE_FEED}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GenerateWorkFlowEraser = async (callback, payload) => {
  const url = `${API_CONSTANTS.GENRATE_WORK_FLOW_ERASER}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GenerateWorkFlowUpscaleExtend = async (callback, payload) => {
  const url = `${API_CONSTANTS.GENRATE_WORK_FLOW_UPSCALE_EXTEND}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GenerateWorkFlowUpscaleCreative = async (callback, payload) => {
  const url = `${API_CONSTANTS.GENRATE_WORK_FLOW_UPSCALE_CREATIVE}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GenerateWorkFlowUpscaleCharacter = async (callback, payload) => {
  const url = `${API_CONSTANTS.GENRATE_WORK_FLOW_UPSCALE_CHARACTER}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const CreateOrUpdateProject = async (callback, payload) => {
  const url = `${API_CONSTANTS.CREATE_OR_UPDATE_PROJECT}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetProjectDetailsById = async (callback, payload) => {
  const queryString = `?projectId=${payload.projectId}`;
  const url = `${API_CONSTANTS.GET_PROJECT_DETAILS_BY_ID + queryString}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetAllProjectsForCurrentUser = async (callback, teamId) => {
  const isTeamId = teamId !== undefined ? teamId : 0;
  const url = `${API_CONSTANTS.GET_ALL_PROJECTS_FOR_USER}?teamId=${isTeamId}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const GetDesignLibraryUser = async (callback, payload) => {
  const url = `${API_CONSTANTS.GET_DESIGN_LIBRARY}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET,
    payload
  ).catch(handleNetworkError);
};

export const GetFirstTierUser = async (callback, payload) => {
  const url = `${API_CONSTANTS.GET_FIRST_TIER}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetSecondTierUser = async (callback, payload) => {
  const url = `${API_CONSTANTS.GET_SECOND_TIER}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetThirdTierUser = async (callback, payload) => {
  const url = `${API_CONSTANTS.GET_THIRD_TIER}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetThirdTierAndStyleUser = async (callback, payload) => {
  const url = `${API_CONSTANTS.GET_THIRD_TIER_AND_STYLE}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetStyleUser = async (callback, payload) => {
  const url = `${API_CONSTANTS.GET_STYLE}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetCameraViewUser = async (callback, payload) => {
  const url = `${API_CONSTANTS.GET_CAMERA_VIEW}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetCameraViewUrlUser = async (callback) => {
  const url = `${API_CONSTANTS.GET_CAMERA_VIEW_URL}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const GetStylizeFiltersList = async (callback, payload) => {
  const url = `${API_CONSTANTS.GET_STYLIZE_FILTERS_LIST}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET,
    payload
  ).catch(handleNetworkError);
};

export const GetAllImagesCurrentUserGallery = async (callback, payload) => {
  const url = `${API_CONSTANTS.GET_ALL_IMAGES_CURRENT_USER_GALLERY}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetAllImagesProjectByIdMongo = async (callback, payload) => {
  const url = `${API_CONSTANTS.GET_ALL_IMAGES_PROJECT_BY_ID_MONGO}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetAllImagesCurrentUserGalleryMongo = async (
  callback,
  payload
) => {
  const isTeamId = payload?.teamId !== undefined ? payload?.teamId : 0;
  const queryString = `?searchString=${payload.searchString}&pageIndex=${payload.pageIndex}&pageSize=${payload.pageSize}&chinese=${payload.chinese}&teamId=${isTeamId}&userProject=${payload.userProject}&isFavorite=${payload.isFavorite}`;
  const url = `${
    API_CONSTANTS.GET_ALL_IMAGES_CURRENT_USER_GALLERY_MONGO + queryString
  }`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const GetAllImagesCommunityGalleryMongo = async (callback, payload) => {
  const queryString = `?searchString=${payload.searchString}&pageIndex=${payload.pageIndex}&pageSize=${payload.pageSize}&chinese=${payload.chinese}&isFavorite=${payload.isFavorite}`;
  const url = `${API_CONSTANTS.GET_ALL_IMAGES_GALLERY_MONGO + queryString}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const GetScanQrCodeData = async (callback, code) => {
  const queryString = `?code=${code}`;
  const url = `${API_CONSTANTS.SCAN_QR_CODE + queryString}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST
  ).catch(handleNetworkError);
};

export const CallAwsUrl = async (callback, imageUrl) => {
  const url = `${API_CONSTANTS.CALL_AWS_URL}?url=${imageUrl}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const UplaodMedia = async (callback, payload) => {
  const queryString = `?myFile=${payload.myFile}`;
  const url = `${API_CONSTANTS.UPLOAD_MEDIA + queryString}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const DeleteImagesGallery = async (callback, payload) => {
  const url = `${API_CONSTANTS.DELETE_IMAGES_GALLERY}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.PUT,
    payload
  ).catch(handleNetworkError);
};

export const GetLatestVersion = async (callback, language, preVersion) => {
  const url = `${API_CONSTANTS.GET_LATEST_VERSION}?chinese=${language}&preVersion=${preVersion}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const GetLatestProjectId = async (callback, teamId) => {
  const isTeamId = teamId !== undefined ? teamId : 0;
  const url = `${API_CONSTANTS.GET_LATEST_PROJECT_ID}?teamId=${isTeamId}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const GetUserChatRooms = async (callback, teamId) => {
  const isTeamId = teamId !== undefined ? teamId : 0;
  const url = `${API_CONSTANTS.GET_CHAT_ROOMS}?teamId=${isTeamId}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const AddUserChatRoom = async (callback, payload) => {
  const url = `${API_CONSTANTS.ADD_CHAT_ROOM}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const UpdateUserChatRoomName = async (
  callback,
  chatRoomId,
  chatRoomName
) => {
  const url = `${API_CONSTANTS.UPDATE_CHAT_ROOM_NAME}?chatRoomId=${chatRoomId}&chatRoomName=${chatRoomName}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST
  ).catch(handleNetworkError);
};

export const DeleteUserChatRoom = async (callback, chatRoomId) => {
  const url = `${API_CONSTANTS.DELETE_CHAT_ROOM}?chatRoomId=${chatRoomId}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.DELETE
  ).catch(handleNetworkError);
};

export const GetUserChatMessages = async (callback, chatRoomId) => {
  const url = `${API_CONSTANTS.GET_CHAT_ROOM_MESSAGE}?chatRoomId=${chatRoomId}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const SendChatMessage = async (callback, payload) => {
  const url = `${API_CONSTANTS.SEND_CHAT_ROOM_MESSAGE}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetMultipleLogins = (callback) => {
  const url = `${API_CONSTANTS.CHECK_MULTIPLE_LOGINS}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const GetUserSubScriptionDetails = async (callback, teamId) => {
  const isTeamId = teamId !== undefined ? teamId : 0;
  const url = `${API_CONSTANTS.GET_USER_SUBSCRIPTOIN_DETAILS}?teamId=${isTeamId}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const GetAllSubscriptionAndAddons = async (callback, lang, teamId) => {
  const isTeamId = teamId !== undefined ? teamId : 0;
  const url = `${API_CONSTANTS.GET_USER_SUBSCRIPTION_AND_ADDONS}?chinese=${lang}&teamId=${isTeamId}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const GetAllSubscriptionPlans = async (
  callback,
  categoryId,
  lang,
  bonusId,
  teamId
) => {
  const isTeamId = teamId !== undefined ? teamId : 0;
  const isBonusId = bonusId !== undefined ? bonusId : 0;
  const url = `${API_CONSTANTS.GET_ALL_SUBSCRIPTION_PLANS}?categoryId=${categoryId}&chinese=${lang}&bonusId=${isBonusId}&teamId=${isTeamId}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const GetAllAddOnPlans = async (callback, lang, bonusId, teamId = 0) => {
  const isTeamId = teamId !== undefined ? teamId : 0;
  const isBonusId = bonusId !== undefined ? bonusId : 0;
  const url = `${API_CONSTANTS.GET_ALL_ADDON_PLANS}?chinese=${lang}&bonusId=${isBonusId}&teamId=${isTeamId}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const BuySubscriptionPlan = async (callback, payload) => {
  const url = `${API_CONSTANTS.BUY_SUBSCRIPTION_PLAN}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const BuyAddonPlan = async (callback, payload) => {
  const url = `${API_CONSTANTS.BUY_ADDON_PLAN}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetBillingDetails = async (callback, lang, teamId) => {
  const isTeamId = teamId !== undefined ? teamId : 0;
  const url = `${API_CONSTANTS.GET_BILLING_DETAILS}?chinese=${lang}&teamId=${isTeamId}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const GetUserPrivileges = async (callback, teamId = 0) => {
  const isTeamId = teamId !== undefined ? teamId : 0;
  const url = `${API_CONSTANTS.GET_USER_PRIVILAGES}?teamId=${isTeamId}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const CancelUserMembership = async (callback) => {
  const url = `${API_CONSTANTS.CANCEL_USER_MEMBERSHIP}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const GetUserMembershipUpgrade = async (
  callback,
  membershipId,
  chinese,
  teamId
) => {
  const isTeamId = teamId !== undefined ? teamId : 0;
  const url = `${API_CONSTANTS.GET_MEMBERSHIP_SWITCH_DETAILS}?membershipId=${membershipId}&chinese=${chinese}&teamId=${isTeamId}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const GetAddonSitchDetails = async (callback, addonId, chinese) => {
  const url = `${API_CONSTANTS.GET_ADDON_POPUP_DATA}?addonId=${addonId}&chinese=${chinese}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const GetUserSwitchStealth = async (callback) => {
  const url = `${API_CONSTANTS.GET_SWITCH_STEALTH}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const GetUserStealthData = async (callback, teamId) => {
  const isTeamId = teamId !== undefined ? teamId : 0;
  const url = `${API_CONSTANTS.GET_STEALTH_DATA}?teamId=${isTeamId}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const MakeNewPayment = async (
  callback,
  totalAmount,
  addonId,
  membershipId,
  isUpgrade,
  teamId
) => {
  const isTeamId = teamId !== undefined ? teamId : 0;
  const url = `${API_CONSTANTS.MAKE_NEW_PAYMENT}?totalAmount=${totalAmount}&addonId=${addonId}&membershipId=${membershipId}&isUpgrade=${isUpgrade}&teamId=${isTeamId}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST
  ).catch(handleNetworkError);
};

export const DecodeUrl = async (callback, payload) => {
  // const payment = paymenturl ? paymenturl.toString() : '';
  const url = `${API_CONSTANTS.DECODE_URL}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetAllFAQs = async (callback, chinese) => {
  const url = `${API_CONSTANTS.GET_ALL_FAQS}?chinese=${chinese}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const GetOneRecordApi = async (callback, payload) => {
  const url = `${API_CONSTANTS.GET_ONE_RECORD}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetCurrentUserExampleProjects = async (callback, payload) => {
  const url = `${API_CONSTANTS.GET_CURRENT_USER_EXAMPLE_PROJECTS}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET,
    payload
  ).catch(handleNetworkError);
};

export const GetOemInfo = async (callback, payload) => {
  const url = `${API_CONSTANTS.GET_OEM_INFO}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const CreateNewTeam = async (callback, payload) => {
  const url = `${API_CONSTANTS.CREATE_NEW_TEAM}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetAllUserTeams = async (callback) => {
  const url = `${API_CONSTANTS.GET_ALL_USER_TEAMS}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const GetTeamDetailsById = async (callback, teamId) => {
  const url = `${API_CONSTANTS.GET_USER_TEAM_DETAILS}?teamId=${teamId}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const DeleteUserTeam = async (callback, teamId) => {
  const url = `${API_CONSTANTS.DELETE_USER_TEAM}?teamId=${teamId}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.DELETE
  ).catch(handleNetworkError);
};

export const SearchExistingUser = async (callback, search, teamId) => {
  const url = `${API_CONSTANTS.SEARCH_USER}?searchString=${search}&teamId=${teamId}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const InviteExistingUser = async (callback, payload) => {
  const url = `${API_CONSTANTS.INVITE_EXISTING_USER}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetUserTeamsInvites = async (callback, lang) => {
  const url = `${API_CONSTANTS.GET_USER_TEAMS_INVITES}?chinese=${lang}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const AcceprOrRejectTeamInvite = async (callback, payload) => {
  const url = `${API_CONSTANTS.ACCEPT_OR_REJECT_TEAM_INVITES}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const SendInviteNewUser = async (callback, payload) => {
  const url = `${API_CONSTANTS.SEND_INVITE_NEW_USER}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetUserTeamNotification = async (
  callback,
  payload,
  language,
  teamId
) => {
  const queryString = `?pageIndex=${payload.pageIndex}&pageSize=${payload.pageSize}&chinese=${language}&teamId=${teamId}`;
  const url = `${API_CONSTANTS.GET_TEAM_NOTIFICATION + queryString}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const GetUserDetailsOfTeam = async (callback, teamId) => {
  const isTeamId = teamId !== undefined ? teamId : 0;
  const url = `${API_CONSTANTS.GET_USER_DETAILS_OF_TEAM}?teamId=${isTeamId}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const ExitFromTeam = async (callback, payload) => {
  const url = `${API_CONSTANTS.EXIT_FROM_TEAM}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const RemoveUserFromTeam = async (callback, payload) => {
  const url = `${API_CONSTANTS.REMOVE_USER_FROM_TEAM}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetAllPrivateModel = async (callback, teamId) => {
  const isTeamId = teamId !== undefined ? teamId : 0;
  const url = `${API_CONSTANTS.GET_ALL_PRIVATE_MODEL}?teamId=${isTeamId}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST
  ).catch(handleNetworkError);
};

export const UpdateFavoriteImage = async (callback, payload) => {
  const url = `${API_CONSTANTS.FAVORITE_IMAGE_OR_NOT}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetAllTeamDetailedData = async (callback, payload) => {
  const url = `${API_CONSTANTS.GET_ALL_TEAM_DETAILED_DATA}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetAllTeamGenerationData = async (callback, payload) => {
  const url = `${API_CONSTANTS.GET_ALL_TEAM_GENRATION_DATA}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetGraphicStyleData = async (callback, payload) => {
  const url = `${API_CONSTANTS.GET_ALL_GRAPHIC_STYLE_DATA}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

// Admin Api's

export const AdminLogin = async (callback, payload) => {
  const url = `${API_CONSTANTS.LOGIN}`;
  return fetchAdminCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetAdminPhoneLengthCountry = async (callback, payload) => {
  const url = `${API_CONSTANTS.GET_PHONE_NUMBER_LENGTH_WITH_COUNTRY}`;
  return fetchAdminCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetAdminScanQrCodeData = async (callback, code) => {
  const queryString = `?code=${code}`;
  const url = `${API_CONSTANTS.SCAN_QR_CODE + queryString}`;
  return fetchAdminLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST
  ).catch(handleNetworkError);
};

export const GetCurrentAdminDetails = async (callback, payload) => {
  const url = `${API_CONSTANTS.GET_CURRENT_USER}`;
  return fetchAdminLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET,
    payload
  ).catch(handleNetworkError);
};

export const GetAllUSersList = async (callback, payload) => {
  const url = `${API_CONSTANTS.GET_ALL_USERS_LIST}`;
  return fetchAdminLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetSubTeamUserListFromMaster = async (callback, teamId) => {
  const isTeamId = teamId !== undefined ? teamId : 0;
  const url = `${API_CONSTANTS.GET_ALL_USERS_OF_MASTER_TEAM_NOT_JOINED_SUB_TEAM}?teamId=${isTeamId}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const GetAllTutorialsList = async (callback, language) => {
  const queryString = `?chinese=${language}`;
  const url = `${API_CONSTANTS.GET_ALL_TUTORIALS_LIST + queryString}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const GetTutorialDetailsById = async (
  callback,
  language,
  headingId,
  cardId
) => {
  const queryString = `?headingId=${headingId}&chinese=${language}&cardId=${cardId}`;
  const url = `${API_CONSTANTS.GET_TUTORIAL_DETAILS_BY_ID + queryString}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const UpdateLanguageAndVersion = async (callback, payload) => {
  const url = `${API_CONSTANTS.UPDATE_LANG_VERSION}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetCurrentTeamNotPresentUsers = async (callback, teamId) => {
  const queryString = `?teamId=${teamId}`;
  const url = `${API_CONSTANTS.GET_TEAM_NOT_PRESENT_USERS + queryString}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const GetMembersDataOfTeam = async (callback, payload) => {
  const url = `${API_CONSTANTS.GET_USERS_DATA_OF_TEAM}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const RemoveUserFromAllTeams = async (callback, payload) => {
  const url = `${API_CONSTANTS.REMOVE_USER_FROM_ALL_TEAMS}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetMyPlanPrivilegeSummary = async (callback) => {
  const url = `${API_CONSTANTS.GET_MY_PRIVILEGE_SUMMARY}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const GetUserTeamsOrder = async (callback) => {
  const url = `${API_CONSTANTS.GET_USER_TEAMS_ORDER}`;
  return fetchLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};

export const GetAdminDataMySQL = async (callback, payload) => {
  // const queryString = `?startDate=${startDate}&endDate=${endDate}`;
  const url = `${API_CONSTANTS.GET_ADMIN_DATA_MY_SQL}`;
  return fetchAdminLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST,
    payload
  ).catch(handleNetworkError);
};

export const GetAdminDataMongo = async (callback) => {
  const url = `${API_CONSTANTS.GET_ADMIN_DATA_MONGO}`;
  return fetchAdminLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.POST
  ).catch(handleNetworkError);
};

export const GetGenerationSeedData = async (callback, id) => {
  const queryString = `?projectMediaId=${id}`;
  const url = `${API_CONSTANTS.GET_GENERATION_SEED_DATA + queryString}`;
  return fetchAdminLoginCall(
    (response) => {
      callback(response);
    },
    url,
    API_METHODS.GET
  ).catch(handleNetworkError);
};