import React, { useContext, useEffect, useState } from "react";
import "./CreateProject.scss";
import { useTranslation } from "react-i18next";
import Button from "../../button";
import { CreateOrUpdateProject } from "../../../utils/apiCalls";
import { UserSocketContext } from "../../../store/UserSocketData";
import { useNavigate } from "react-router-dom";
import { handleCountCharacterNaming } from "../../../utils/workspaceUtils";

const CreateProject = ({ onClose, setIsLoader1, isLoader1, open, filterProjectsList }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateSocketData } = useContext(UserSocketContext);
  const isActiveTeam = JSON.parse(localStorage.getItem("activeTeam"));
  const [projectName, setProjectName] = useState("");
  const [remaining, setRemaining] = useState(0);
  const [error,setError] = useState("");
  const maxLimit = 24;

  useEffect(() => {
    setProjectName("");
  }, [open])
  
  const handleChange = (e) => {
    setError("");
    const {remainingChars,inputText} = handleCountCharacterNaming(e);
    setProjectName(inputText);
    setRemaining(Math.max(0, remainingChars));
  };

  const handleConfirm = () => {
    setIsLoader1(true);
    const isDuplicate = filterProjectsList?.some(pro => pro?.name === projectName);
    if (isDuplicate) {
      setError(t("project_name_duplicated"));
      setIsLoader1(false);
      return;
    }

    const teamId = isActiveTeam?.teamId !== 0.1 ? isActiveTeam?.teamId : 0;
    const payload = {
      name: projectName,
      teamId: teamId,
    };
    if (payload) {
      CreateOrUpdateProject((res) => {
        setProjectName("");
        setIsLoader1(false);
        const id = res?.data?.id;
        localStorage.setItem(`newProject_${id}`, JSON.stringify(id));
        navigate(`/workspace/${id}`);
        if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        }
      }, payload);
    }
  };

  const handleClose = () =>{
    onClose();
    setProjectName("");
  }

  return (
    <div className="project-create">
      <div className="project-create__main">
        <div className="project-create__main__container">
          <div className="project-create__main__container__heading">
            <h2>{t("create_project")}</h2>
          </div>
          <div className="project-create__main__container__content">
            <h4>{t("project_name")}</h4>
            <div className="project-create__main__container__content__input">
              <input
                value={projectName}
                onChange={(e) => {
                  let formattedValue = e.target.value.replace(/\s+/g, " ");
                  handleChange(formattedValue);
                }}
                placeholder={t("enter_project_name")}
              />
              <h6 style={{ color: remaining === maxLimit && "red" }}>
                <span>{remaining}</span> / {maxLimit}
              </h6>
              {error !== "" && <p>{error}</p>}
            </div>
          </div>
          <div className="project-create__main__container__buttons">
            <div className="project-create__main__container__buttons__main">
              <Button
                buttonClick={handleClose}
                buttonClass={"button_link"}
                isBtnDisabled={false}
                buttonText={t("cancel")}
              />
              <Button
                buttonClick={handleConfirm}
                buttonClass="button"
                isBtnDisabled={isLoader1 ? true : false}
                buttonText={t("confirm")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProject;
