/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from "react";
import "./SubscriptoinDetails.scss";
import tick from "../../../assets/images/icons/tick-sub.png";
import arrow from "../../../assets/images/icons/arrow.png";
import cross from "../../../assets/images/icons/cross.png";
import gif from "../../../assets/images/workspace/upscale-gif.gif";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useNavigate } from "react-router-dom";
import {
  GetAllAddOnPlans,
  GetAllSubscriptionPlans,
  BuySubscriptionPlan,
  BuyAddonPlan,
  CancelUserMembership,
} from "../../../utils/apiCalls";
import MembershipSucessfull from "../membershipSucessfull/MembershipSucessfull";
import Confirm from "../../confirmModal/confirm";
import { UserContext } from "../../../store/UserDetailsStore";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper/modules";
import SwiperCore from "swiper/core";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/pagination";
import UpgradeDowngradePopup from "../Upgrade-DownradePopup/UpgradeDowngradePopup";
import ChangeCommitment from "../change-commitment/ChangeCommitment";
import RemoveSchedulePlan from "../remove-schedule-plan/RemoveSchedulePlan";
import CancelMembershipSuccess from "../CancelMembershipSuccess/CancelMembershipSuccess";
import PaymentPopup from "../PaymentPopup/PaymentPopup";
import { ResponsiveDataContext } from "../../../store/ResponsiveData";
import { useTranslation } from "react-i18next";
import AddonPopup from "../AddonPopup/AddonPopup";
import { UserSocketContext } from "../../../store/UserSocketData";
import { TeamDesignContext } from "../../../store/TeamDesignData";
SwiperCore.use([Scrollbar]);

const SubscriptionDetails = () => {
  const { t } = useTranslation();
  const addonApipiRef = useRef(false);
  const subscriptionApipiRef = useRef(false);
  const isActiveTeam = JSON.parse(localStorage.getItem("activeTeam"));
  const {
    getUserAllPrivilages,
    isLoginUserDetails,
    getAllSubscriptionAndAddons,
    isUserMembershipDetails,
  } = useContext(UserContext);
  const { responsiveData } = useContext(ResponsiveDataContext);
  const { updateSocketData, handleShowSubscription } = useContext(UserSocketContext);
  const { handleOpenCreateTeam } = useContext(TeamDesignContext);
  const navigate = useNavigate();
  const [allSubscriptionPlans, setAllSubscriptionPlans] = useState([]);
  const [allAddonPlans, setAllAddonPlans] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [isSubSuccessfull, setIsSubSuccessfull] = useState(false);
  const [isCancelSuccessfull, setIsCancelSuccessfull] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isConfirmMessage, setIsConfirmMessage] = useState("");
  const [isMessage, setIsMessage] = useState(false);
  const [isPlanDuration, setIsPlanDuration] = useState(false);
  const [isUpgradePopup, setIsUpgradePopup] = useState(false);
  const [isMemberShipData, setIsMembershipData] = useState();
  const [isCommitmentPopup, setIsCommitmentPopup] = useState(false);
  const [isRemovePopup, setIsRemovePopup] = useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [isMessage1, setIsMessage1] = useState(false);
  const [isAddonPopup, setIsAddonPopup] = useState(false);
  const [isAddonId, setIsAddonId] = useState(null);
  const [isLoad, setIsLoad] = useState(false);

  let lang = localStorage.getItem("language") === "ch";

  useEffect(() => {
    if (!addonApipiRef.current) {
      addonApipiRef.current = true;
      getAllAddonPlans();
    }
  }, []);

  useEffect(() => {
    if (!subscriptionApipiRef.current) {
      subscriptionApipiRef.current = true;
      getAllSubscriptionPlans();
    }
  }, [isPlanDuration, isLoginUserDetails]);

  const getAllSubscriptionPlans = () => {
    setIsLoad(true);
    const categoryId = isPlanDuration === true ? 2 : 1;
    //if teammode 2, else depend on isplan duration, set as yearly or monthly
    const bonusId = 0;
    const teamId = 0;
    GetAllSubscriptionPlans(
      (res) => {
        setAllSubscriptionPlans(res?.data);
        setIsLoad(false);
        if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        }
      },
      categoryId,
      lang,
      bonusId,
      teamId
    );
  };

  const getAllAddonPlans = () => {
    const bonusId = 0;
    const teamId = 0;
    setIsLoad(true);
    GetAllAddOnPlans(
      (res) => {
        setAllAddonPlans(res.data);
        setIsLoad(false);
        if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        }
      },
      lang,
      bonusId,
      teamId
    );
  };

  const handleOpenCloseSuccess = () => {
    setIsSubSuccessfull(!isSubSuccessfull);
  };

  const handleOpenCloseCancel = () => {
    setIsCancelSuccessfull(!isCancelSuccessfull);
  };

  const handleOpenClosePayment = () => {
    setIsPaymentSuccess(!isPaymentSuccess);
  };

  const handleChangePlanDuration = () => {
    setIsPlanDuration(!isPlanDuration);
  };

  const handleBuySubscription = () => {
    const lang = localStorage.getItem("language");
    setIsLoader(true);
    const payload = {
      membershipId: isMemberShipData?.id,
      discountId: null,
      isUpgrade:
        isUserMembershipDetails?.membership?.name === "Free Plan" ||
        isUserMembershipDetails?.membership?.name === "计划" ||
        isMemberShipData?.upgradeOrDowngrade === "Buy Now" ||
        isMemberShipData?.upgradeOrDowngrade === "立即购买"
          ? true
          : isMemberShipData?.id === 1
          ? false
          : isMemberShipData?.upgradeOrDowngrade === "Upgrade" ||
            isMemberShipData?.upgradeOrDowngrade === "升级计划"
          ? true
          : (isMemberShipData?.upgradeOrDowngrade === "Change commitment" ||
              isMemberShipData?.upgradeOrDowngrade === "更改承诺") &&
            (isMemberShipData?.category === "Yearly" ||
              isMemberShipData?.category === "每月")
          ? true
          : false,
      teamId: 0,
      chinese: lang === "ch" ? true : false,
    };
    if (payload) {
      BuySubscriptionPlan((res) => {
        setIsLoader(false);
        if (res?.status === 200) {
          setIsConfirmMessage(res?.data);
          setIsSubSuccessfull(true);
          getAllSubscriptionPlans();
          setIsConfirmModal(false);
          getAllSubscriptionAndAddons();
          getUserAllPrivilages();
          getAllAddonPlans();
        } else {
          setIsLoader(false);
          setIsConfirmModal(true);
        }
        if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        }
      }, payload);
    }
  };

  const handleBuyAddon = (addOnId) => {
    setIsAddonId(addOnId);
    setIsAddonPopup(!isAddonPopup);
  };

  const handleAddonBuy = (addOnId) => {
    setIsAddonPopup(!isAddonPopup);
    setIsLoader(true);
    const payload = {
      addOnId: addOnId,
      discountId: null,
      teamId: 0,
    };
    if (payload) {
      BuyAddonPlan((res) => {
        if (res?.status === 200) {
          setIsLoader(false);
          setIsSubSuccessfull(true);
          setIsConfirmMessage(res?.data);
          getAllAddonPlans();
          setIsConfirmModal(false);
          getAllSubscriptionAndAddons();
          getUserAllPrivilages();
        } else {
          setIsLoader(false);
          setIsMessage(res?.message);
          setIsConfirmModal(true);
        }
        if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        }
      }, payload);
    }
  };

  const handleContactUs = () => {
    handleShowSubscription();
    navigate("/contact");
  };

  const handleCloseUpgradePopup = (sub) => {
    const payload = {
      membershipId: isMemberShipData?.id,
      discountId: null,
      isUpgrade:
        isUserMembershipDetails?.membership?.name === "Free Plan" ||
        isUserMembershipDetails?.membership?.name === "计划" ||
        isMemberShipData?.upgradeOrDowngrade === "Buy Now" ||
        isMemberShipData?.upgradeOrDowngrade === "立即购买"
          ? true
          : isMemberShipData?.id === 1
          ? false
          : isMemberShipData?.upgradeOrDowngrade === "Upgrade" ||
            isMemberShipData?.upgradeOrDowngrade === "升级计划"
          ? true
          : (isMemberShipData?.upgradeOrDowngrade === "Change commitment" ||
              isMemberShipData?.upgradeOrDowngrade === "更改承诺") &&
            (isMemberShipData?.category === "Yearly" ||
              isMemberShipData?.category === "每月")
          ? true
          : false,
    };
    localStorage.setItem("payload", JSON.stringify(payload));
    if (
      sub?.upgradeOrDowngrade === "Upgrade" ||
      sub?.upgradeOrDowngrade === "升级计划" ||
      sub?.upgradeOrDowngrade === "Downgrade" ||
      sub?.upgradeOrDowngrade === "降级计划" ||
      sub?.upgradeOrDowngrade === "Buy Now" ||
      sub?.upgradeOrDowngrade === "立即购买"
    ) {
      setIsMembershipData(sub);
      setIsUpgradePopup(!isUpgradePopup);
    } else if (
      sub?.upgradeOrDowngrade === "Change Scheduled" ||
      sub?.upgradeOrDowngrade === "更改计划"
    ) {
      setIsMembershipData(sub);
      setIsRemovePopup(!isRemovePopup);
    } else {
      setIsMembershipData(sub);
      setIsCommitmentPopup(!isCommitmentPopup);
    }
  };

  const handleCancelPlan = () => {
    setIsLoader(true);
    CancelUserMembership((res) => {
      if (res?.status === 200) {
        handleOpenCloseCancel();
        getAllSubscriptionAndAddons();
        setIsMessage1(res?.message);
        setIsRemovePopup(!isRemovePopup);
        setIsLoader(false);
      }
      if (res?.message === "Invalid access token") {
        updateSocketData({
          isExitPopup: true,
        });
      }
    });
  };

  const handleCreate = () => {
    handleShowSubscription();
    handleOpenCreateTeam();
  };

  const isUpgradeBoolean =
    isUserMembershipDetails?.membership?.name === "Free Plan" ||
    isUserMembershipDetails?.membership?.name === "计划" ||
    isMemberShipData?.upgradeOrDowngrade === "Buy Now" ||
    isMemberShipData?.upgradeOrDowngrade === "立即购买"
      ? true
      : isMemberShipData?.id === 1
      ? false
      : isMemberShipData?.upgradeOrDowngrade === "Upgrade" ||
        isMemberShipData?.upgradeOrDowngrade === "升级计划"
      ? true
      : (isMemberShipData?.upgradeOrDowngrade === "Change commitment" ||
          isMemberShipData?.upgradeOrDowngrade === "更改承诺") &&
        (isMemberShipData?.category === "Yearly" ||
          isMemberShipData?.category === "每月")
      ? true
      : false;

  const isMemberShipId = isMemberShipData?.id;

  const formattedMessage1 = t("subscription_solo_content")
    ? t("subscription_solo_content")?.trim()?.replace(/\/n/g, "<br>")
    : "";
  const formattedMessage2 = t("addon_content1")
    ? t("addon_content1")?.trim()?.replace(/\/n/g, "<br>")
    : "";
  const formattedMessage3 = t("addon_content2")
    ? t("addon_content2")?.trim()?.replace(/\/n/g, "<br>")
    : "";
  const formattedMessage4 = t("addon_content3")
    ? t("addon_content3")?.trim()?.replace(/\/n/g, "<br>")
    : "";
  const formattedMessage5 = t("subscription_team_content")
    ? t("subscription_team_content")?.trim()?.replace(/\/n/g, "<br>")
    : "";

  return (
    <div className="sub-popup" onClick={handleShowSubscription}>
      <div className="sub-popup__container">
        <div
          className="sub-popup__section"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="sub-popup__section__header">
            <h2>{t("subscription")}</h2>
            <p>{t("Subscription_content")}</p>
          </div>
          <div className="sub-popup__section__main" data-scrollable="true">
            <div className="sub-popup__section__main__details">
              <div className="sub-popup__section__main__details__tabs">
                <Tabs defaultIndex={0}>
                  <TabList>
                    <Tab>
                      <div className="sub-popup__section__main__details__tab">
                        <span>{t("Subscription_Plan")}</span>
                      </div>
                    </Tab>
                    <Tab>
                      <div className="sub-popup__section__main__details__tab">
                        <span>{t("Add-on")}</span>
                      </div>
                    </Tab>
                  </TabList>
                  <TabPanel>
                    <div
                      className={`sub-popup__section__main__details__content ${
                        allSubscriptionPlans?.length <= 3 ? "center" : "start"
                      }`}
                      data-draggable="true"
                      data-scrollable="true"
                    >
                      <p
                        dangerouslySetInnerHTML={{
                          // __html: formattedMessage1,
                          __html: "",
                        }}
                        className={`content1 ${
                          allSubscriptionPlans?.length !== 0 ? "" : "no-data"
                        }`}
                      ></p>
                      {allSubscriptionPlans?.length !== 0 ? (
                        <Swiper
                          spaceBetween={10}
                          slidesPerView={responsiveData ? 3 : 4}
                          modules={[Scrollbar]}
                          scrollbar={{
                            hide: false,
                          }}
                        >
                          {allSubscriptionPlans?.length !== 0 &&
                            allSubscriptionPlans?.length &&
                            allSubscriptionPlans?.map((sub, index) => {
                              return (
                                <SwiperSlide key={index}>
                                  <div className="sub-popup__section__main__details__content__sec">
                                    <div className="sub-popup__section__main__details__content__sec__heading">
                                      <h4 style={{ color: sub?.color }}>
                                        {sub?.name}
                                      </h4>
                                    </div>
                                    {/* <div className="sub-popup__section__main__details__content__sec__currency">
                                      <h2>
                                        ￥{sub?.price === -1 ? "?" : sub?.price}{" "}
                                        {sub?.price !== -1 && (
                                          <span>/ {t("month")}</span>
                                        )}
                                      </h2>
                                    </div> */}
                                    {/* <div className="sub-popup__section__main__details__content__sec__plan">
                                      {sub?.price !== -1 ? (
                                        <>
                                          {sub?.name !== "Free Plan" ? (
                                            <p>
                                              {isPlanDuration !== true
                                                ? `${t("Billed_Monthly")}`
                                                : `${t("Billed_Yearly")}`}
                                            </p>
                                          ) : (
                                            <p>{t("Lifetime")}</p>
                                          )}
                                        </>
                                      ) : (
                                        <p>&nbsp;</p>
                                      )}
                                    </div> */}
                                    <div className="sub-popup__section__main__details__content__sec__button">
                                      {sub?.price === -1 ? (
                                        <button
                                          disabled={false}
                                          onClick={handleContactUs}
                                        >
                                          <span>{sub?.upgradeOrDowngrade}</span>
                                        </button>
                                      ) : (
                                        <>
                                          {sub?.currentActivePlan === true ? (
                                            <button className="disabled">
                                              <span>{t("Current_Plan")}</span>
                                            </button>
                                          ) : (
                                            <button
                                              className={`upgrade ${
                                                sub?.upgradeOrDowngrade ===
                                                  "Change Scheduled" ||
                                                sub?.upgradeOrDowngrade ===
                                                  "更改计划"
                                                  ? "schedule"
                                                  : ""
                                              }`}
                                              onClick={(e) =>
                                                handleCloseUpgradePopup(sub)
                                              }
                                            >
                                              {sub?.upgradeOrDowngrade ===
                                                "Change Scheduled" ||
                                              sub?.upgradeOrDowngrade ===
                                                "更改计划" ? (
                                                <>
                                                  <span className="new">
                                                    {t("Cancel_Change")}
                                                  </span>
                                                  <span className="old">
                                                    {sub?.upgradeOrDowngrade}
                                                  </span>
                                                </>
                                              ) : (
                                                <span>
                                                  {sub?.upgradeOrDowngrade}
                                                </span>
                                              )}
                                            </button>
                                          )}
                                        </>
                                      )}
                                      {sub?.name !== "Free Plan" ? (
                                        <>
                                          {/* {isActiveTeam?.teamId === 0.1 && (
                                              <p
                                                onClick={
                                                  handleChangePlanDuration
                                                }
                                              >
                                                {isPlanDuration !== true
                                                  ? `${t("bill_saving")}`
                                                  : `${t(
                                                      "bill_saving_monthly"
                                                    )}`}
                                                <img
                                                  src={arrow}
                                                  alt="arrow-icon"
                                                />
                                              </p>
                                            )} */}
                                        </>
                                      ) : (
                                        <p
                                          className={`${
                                            sub?.name === "Free Plan"
                                              ? "hide"
                                              : ""
                                          }`}
                                        ></p>
                                      )}
                                    </div>
                                    <div className="sub-popup__section__main__details__content__sec__points">
                                      {sub?.details?.[0].split(';').map((point, index) => {
                                        let formattedMessage = point ? point?.trim()?.replace(/\\n/g, "<br>") : "";
                                        return (
                                          <div
                                            className="sub-popup__section__main__details__content__sec__point"
                                            key={index}
                                          >
                                            {(formattedMessage.substring(0, 3) === 'Not' || formattedMessage.substring(0, 2) === '不可') ? (
                                              <img src={cross} alt="cross-icon" />
                                            ) : (
                                              <img src={tick} alt="tick-icon" />
                                            )}
                                            {/*<img src={tick} alt="tick-icon" />*/}
                                            <p
                                              dangerouslySetInnerHTML={{
                                                __html: formattedMessage,
                                              }}
                                            ></p>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </SwiperSlide>
                              );
                            })}
                        </Swiper>
                      ) : (
                        <div className="gif-icon">
                          {isLoad ? (
                            <img className="gif" src={gif} alt="gif" />
                          ) : (
                            // <p>{t("no_plans_available")}</p>
                            <p></p>
                          )}
                        </div>
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel>
                    {!isLoad ? (
                      allAddonPlans?.length !== 0 ? (
                        <div>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: formattedMessage3,
                            }}
                            className="content1"
                          ></p>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: formattedMessage4,
                            }}
                            className="content1"
                          ></p>
                          <div
                            className="sub-popup__section__main__details__addon"
                            data-draggable="true"
                            data-scrollable="true"
                          >
                            {allAddonPlans?.length &&
                              allAddonPlans?.length &&
                              allAddonPlans?.map((addon, index) => {
                                const months = addon?.duration / 30;
                                return (
                                  <div
                                    className="sub-popup__section__main__details__addon__sec"
                                    key={index}
                                  >
                                    <div className="sub-popup__section__main__details__addon__sec__heading">
                                      <h6>{addon?.name}</h6>
                                    </div>
                                    <div className="sub-popup__section__main__details__addon__sec__currency">
                                      <h2>
                                        ￥{addon?.price}{" "}
                                        {true ? (
                                          <>
                                            {addon?.duration !== -1 ? (
                                              <span>
                                                {t("subscriptionDetails_for")}{" "}
                                                {addon?.duration} {t("days")}
                                              </span>
                                            ) : (
                                              <span>{t("Lifetime")}</span>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <p>￥{addon?.price} </p>
                                            <span>/ month</span>
                                          </>
                                        )}
                                      </h2>
                                    </div>
                                    {addon?.details?.map((point, index) => {
                                      return (
                                        <div
                                          className="sub-popup__section__main__details__addon__sec__point"
                                          key={index}
                                        >
                                          {/* <img src={tick} alt="tick-icon" /> */}
                                          <p>{point}</p>
                                        </div>
                                      );
                                    })}
                                    <div className="sub-popup__section__main__details__addon__sec__button">
                                      {addon?.currentActivePlan === true ? (
                                        <button className="active">
                                          {addon?.name === "Concurrent1"
                                            ? `${t("maximum_allowed")}`
                                            : `${t("Active")}`}
                                          {/* Active */}
                                        </button>
                                      ) : (
                                        <button
                                          onClick={(e) =>
                                            handleBuyAddon(addon?.id)
                                          }
                                        >
                                          {t("buy_now")}
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      ) : (
                        <div className="gif-icon">
                          <p>{t("no_add_on_plans")}</p>
                        </div>
                      )
                    ) : (
                      <div className="gif-icon">
                        <img className="gif" src={gif} alt="gif" />
                      </div>
                    )}
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
          <div className="sub-popup__section__contact">
            <p>{t("sub_bottom")}</p>
            <button onClick={handleContactUs}>{t("contact_content1")}</button>
          </div>
        </div>
        {isLoader && <img className="loader" src={gif} alt="gif" />}
        {isSubSuccessfull && (
          <MembershipSucessfull
            handleOpenCloseSuccess={handleOpenCloseSuccess}
            isConfirmMessage={isConfirmMessage}
          />
        )}
        {isCancelSuccessfull && (
          <CancelMembershipSuccess
            handleOpenCloseCancel={handleOpenCloseCancel}
            isConfirmMessage={isMessage1}
          />
        )}
      </div>
      {isAddonPopup && (
        <AddonPopup
          isAddonId={isAddonId}
          handleBuyAddon={handleBuyAddon}
          handleAddonBuy={handleAddonBuy}
        />
      )}
      {isUpgradePopup && (
        <UpgradeDowngradePopup
          handleCloseUpgradePopup={handleCloseUpgradePopup}
          handleBuySubscription={handleBuySubscription}
          isMemberShipData={isMemberShipData}
          isPlanDuration={isPlanDuration}
          isUpgradeBoolean={isUpgradeBoolean}
          isMemberShipId={isMemberShipId}
        />
      )}
      {isRemovePopup && (
        <RemoveSchedulePlan
          handleCloseUpgradePopup={handleCloseUpgradePopup}
          handleBuySubscription={handleBuySubscription}
          isMemberShipData={isMemberShipData}
          isPlanDuration={isPlanDuration}
          handleCancelPlan={handleCancelPlan}
        />
      )}
      {isCommitmentPopup && (
        <ChangeCommitment
          handleCloseUpgradePopup={handleCloseUpgradePopup}
          handleBuySubscription={handleBuySubscription}
          isMemberShipData={isMemberShipData}
          isPlanDuration={isPlanDuration}
        />
      )}
      {isPaymentSuccess && (
        <PaymentPopup
          handleOpenClosePayment={handleOpenClosePayment}
          handleOpenCloseSuccess={handleOpenCloseSuccess}
        />
      )}
      {isConfirmModal && (
        <Confirm
          buttonText={"OK"}
          isCancelRequired={false}
          confirmTitle={isMessage}
          onConfirm={() => {
            setIsConfirmModal(false);
          }}
          onCancel={() => {
            setIsConfirmModal(false);
          }}
        />
      )}
    </div>
  );
};

export default SubscriptionDetails;
