import React, { useContext, useEffect, useState } from "react";
import "./CreateOrEditTeam.scss";
import { useTranslation } from "react-i18next";
import { UserSocketContext } from "../../../store/UserSocketData";
import { TeamDesignContext } from "../../../store/TeamDesignData";
import Button from "../../button";
import { CreateNewTeam } from "../../../utils/apiCalls";
import { handleCountCharacterNaming } from "../../../utils/workspaceUtils";

const CreateOrEditTeam = ({
  team,
  edit = false,
  onClose,
  setIsLoader1,
  teamCreated,
  setTeamCreated,
  handleChangeTeam,
  handleExistingMember,
  setTeamData,
  setIsTeamLimitPopup
}) => {
  const { t } = useTranslation();
  const { getAllUserTeams, handleTeamChanges } = useContext(TeamDesignContext);
  const { updateSocketData } = useContext(UserSocketContext);
  const isActiveTeam = JSON.parse(localStorage.getItem("activeTeam"));
  const [teamName, setTeamName] = useState("");
  const [error, setError] = useState("");
  const [remaining, setRemaining] = useState(0);
  const maxLimit = 24;

  const handleConfirm = () => {
    const payload = {
      teamId: edit ? team?.teamId : 0,
      teamName: teamName.trim(),
      teamIcon: "",
      defaultTeam: false,
    };
    if (teamName !== "") {
      setIsLoader1(true);
      CreateNewTeam((res) => {
        if (res?.status === 200) {
          onClose();
          if (edit) {
            if (isActiveTeam?.teamId === team.teamId) {
              localStorage.setItem("activeTeam", JSON.stringify(res?.data));
            }
          }
          handleTeamChanges();
          getAllUserTeams();
          if (!edit && !teamCreated) {
            setTeamCreated(true);
          }
          setIsLoader1(false);
          if (!edit) {
            localStorage.setItem("activeTeam", JSON.stringify(res?.data));
            handleChangeTeam(res?.data);
            handleExistingMember();
          } else {
            if (teamCreated) {
              localStorage.setItem("activeTeam", JSON.stringify(res?.data));
              handleChangeTeam(res?.data);
              handleExistingMember();
            } else {
              setTeamData(res?.data);
            }
          }
        } else if (res?.status === 203) {
          onClose();
          setIsLoader1(false);
          setIsTeamLimitPopup(true);
          // setError(res?.message);
        } else if (res?.status === 400) {
          setIsLoader1(false); //team name already exists
          setError(res?.message);
        } else if (res?.message === "Invalid access token") {
          onClose();
          updateSocketData({
            isExitPopup: true,
          });
        } else {
          setIsLoader1(false);
          setError(res?.message);
        }
      }, payload);
    } else {
      setError(t("team_name_required"));
    }
  };

  useEffect(() => {
    if (edit) {
      handleChange(team?.teamName);
    }
  }, []);

  const handleChange = (e) => {
    const {remainingChars,inputText} = handleCountCharacterNaming(e);
    setTeamName(inputText);
    setRemaining(Math.max(0, remainingChars));
  };

  return (
    <div className="create-new-team">
      <div
        className="create-new-team__container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="create-new-team__section">
          <div className="create-new-team__heading">
            {!edit ? (
              <h2>{t("create_team")}</h2>
            ) : (
              <h2>{t("edit_team_name")}</h2>
            )}
          </div>
          <div className="create-new-team__content">
            <h4>{t("team_name")}</h4>
            <div className="create-new-team__content__input">
              <input
                value={teamName}
                onChange={(e) => {
                  let formattedValue = e.target.value.replace(/\s+/g, " ");
                  handleChange(formattedValue);
                }}
                placeholder={t("enter_team_name")}
              />
              <h6 style={{ color: remaining === maxLimit && "red" }}>
                <span>{remaining}</span> / {maxLimit}
              </h6>
              {error !== "" && <p>{error}</p>}
            </div>
          </div>
          <div className="create-new-team__buttons">
            <div className="create-new-team__buttons__main">
              <Button
                buttonClick={onClose}
                buttonClass={"button_link"}
                isBtnDisabled={false}
                buttonText={t("cancel")}
              />
              <Button
                buttonClick={handleConfirm}
                buttonClass="button"
                isBtnDisabled={false}
                buttonText={t("confirm")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateOrEditTeam;
