/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import "./SideNavStyles.scss";
import aiprojecticon from "../../assets/images/icons/ai-project.png";
import aiprojecticon1 from "../../assets/images/icons/ai-project1.png";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TooltipLeft from "../Tooltips/TooltipLeft";
import { ResponsiveDataContext } from "../../store/ResponsiveData";
import { ClientDataContext } from "../../store/ClientData";
import { UserSocketContext } from "../../store/UserSocketData";
import DesignAIIcon from "../../assets/IconComponents/DesignAIIcon";
import ProjectIcon from "../../assets/IconComponents/ProjectIcon";
import PrivateGalleryIcon from "../../assets/IconComponents/PrivateGalleryIcon";
import CommunityGalleryIcon from "../../assets/IconComponents/CommunityGalleryIcon";
import TeamGalleryIcon from "../../assets/IconComponents/team_gallery";
import LanguageAIIcon from "../../assets/IconComponents/LanguageAIIcon";
import AiriBotIcon from "../../assets/IconComponents/AiriBotIcon";
import AccountIcon from "../../assets/IconComponents/AccountIcon";
import NewsIcon from "../../assets/IconComponents/NewsIcon";
import InfoIcon from "../../assets/IconComponents/InfoIcon";
import TutorialIcon from "../../assets/IconComponents/TutorialIcon";
import AiriFAQIcon from "../../assets/IconComponents/AiriFAQIcon";

const SideNavComponent = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { responsiveData } = useContext(ResponsiveDataContext);
  const { clientData } = useContext(ClientDataContext);
  const { teamMemberNotification } = useContext(UserSocketContext);
  const lang = localStorage.getItem("language");
  const isActiveTeam = JSON.parse(localStorage.getItem("activeTeam"));
  const [hoveredElement, setHoveredElement] = useState(null);
  const [hoverStatus, setHoverStatus] = useState({});
  const [activeLink, setActiveLink] = useState(
    location?.pathname ?? "/projects"
  );

  let activeStyle = {
    opacity: 1,
    fontWeight: 600,
  };

  const handleMouseEnter = (event, index) => {
    setHoveredElement(event.currentTarget);
    setHoverStatus(
      Object.fromEntries(Object.keys(hoverStatus).map((key) => [key, false]))
    );
    setHoverStatus((prev) => ({ ...prev, [index]: true }));
  };

  const handleMouseLeave = (index) => {
    setHoverStatus({ ...hoverStatus, [index]: false });
  };

  const handleActiveLink = (link) => {
    setActiveLink(link);
  };

  return (
    <div className="side-nav">
      <div className="side-nav__container">
        <div className="side-nav__sections">
          <div className="side-nav__section" data-scrollable="true">
            <div className="side-nav__section__main">
              {/* <div className="side-nav__section__main__heading">
                <DesignAIIcon />
                <h4>{t("design_ai")}</h4>
              </div> */}
              <div className="side-nav__section__main__sub">
                <div
                  className={`side-nav__section__main__sub__sec ${
                    activeLink === "/projects" && "active"
                  }`}
                  onMouseEnter={(e) => handleMouseEnter(e, 1)}
                  onMouseLeave={() => handleMouseLeave(1)}
                  onClick={() => handleActiveLink("/projects")}
                >
                  <NavLink
                    to="/projects"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                  >
                    <ProjectIcon
                      fill={activeLink === "/projects" ? "#ffffff" : "#263238"}
                    />
                    <span>{t("workshop")}</span>
                  </NavLink>
                  {!responsiveData && (
                    <TooltipLeft
                      triggerElement={hoveredElement}
                      isVisible={hoverStatus[1]}
                      TooltipContent={t("projects_tooltip")}
                      style={lang === "ch" ? 8 : 15}
                    />
                  )}
                </div>
                <div
                  className={`side-nav__section__main__sub__sec ${
                    activeLink === "/gallery" && "active"
                  }`}
                  onMouseEnter={(e) => handleMouseEnter(e, 2)}
                  onMouseLeave={() => handleMouseLeave(2)}
                  onClick={() => handleActiveLink("/gallery")}
                >
                  <NavLink
                    to="/gallery"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                  >
                    <PrivateGalleryIcon
                      fill={activeLink === "/gallery" ? "#ffffff" : "#263238"}
                    />
                    <span>{t("gallery")}</span>
                  </NavLink>
                  {!responsiveData && (
                    <TooltipLeft
                      triggerElement={hoveredElement}
                      isVisible={hoverStatus[2]}
                      TooltipContent={t("gallery_tooltip")}
                      style={lang === "ch" ? 8 : 22}
                    />
                  )}
                </div>
                <div
                    className={`side-nav__section__main__sub__sec ${
                      activeLink === "/team-gallery" && "active"
                    }`}
                  onMouseEnter={(e) => handleMouseEnter(e, 3)}
                  onMouseLeave={() => handleMouseLeave(3)}
                  onClick={(e) => {
                    if (isActiveTeam?.teamId !== 0.1) {
                      handleActiveLink("/team-gallery");
                    } else {
                      e.preventDefault(); 
                    }
                  }}
                >
                  <NavLink
                    to={isActiveTeam?.teamId !== 0.1 ? "/team-gallery" : ""}
                    style={({ isActive }) => ({
                      cursor: isActiveTeam?.teamId !== 0.1 ? "pointer" : "not-allowed", 
                      ...isActive ? isActiveTeam?.teamId !== 0.1 ? activeStyle :undefined : undefined,
                    })}
                  >
                    <TeamGalleryIcon
                      fill={
                        activeLink === "/team-gallery"
                          ? "#ffffff"
                          : "#263238"
                      }
                    />
                    <span>{t("team_gallery")}</span>
                  </NavLink>
                  {!responsiveData && (
                    <TooltipLeft
                      triggerElement={hoveredElement}
                      isVisible={hoverStatus[3]}
                      TooltipContent={t("team_gallery_tooltip")}
                      style={lang === "ch" ? 5 : 15}
                    />
                  )}
                </div>
                <div
                  className={`side-nav__section__main__sub__sec ${
                    activeLink === "/community-gallery" && "active"
                  }`}
                  onMouseEnter={(e) => handleMouseEnter(e, 4)}
                  onMouseLeave={() => handleMouseLeave(4)}
                  onClick={() => handleActiveLink("/community-gallery")}
                >
                  <NavLink
                    to="/community-gallery"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                  >
                    <CommunityGalleryIcon
                      fill={
                        activeLink === "/community-gallery"
                          ? "#ffffff"
                          : "#263238"
                      }
                    />
                    <span>{t("community_gallery")}</span>
                  </NavLink>
                  {!responsiveData && (
                    <TooltipLeft
                      triggerElement={hoveredElement}
                      isVisible={hoverStatus[4]}
                      TooltipContent={t("cgallery_tooltip")}
                      style={lang === "ch" ? 25 : 40}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="side-nav__section__main__border"></div>
            <div className="side-nav__section__main">
              {/* <div className="side-nav__section__main__heading">
                <LanguageAIIcon />
                <h4>{t("language_ai")}</h4>
              </div> */}
              <div className="side-nav__section__main__sub">
                <div
                  className={`side-nav__section__main__sub__sec ${
                    activeLink === "/airi-bot" && "active"
                  }`}
                  style={{ cursor: "default" }}
                  onClick={() => handleActiveLink("/airi-bot")}
                >
                  <NavLink
                    to="/airi-bot"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                  >
                    <AiriBotIcon
                      fill={activeLink === "/airi-bot" ? "#ffffff" : "#263238"}
                    />
                    <span>{t("gpt_heading")}</span>
                  </NavLink>
                </div>
                {/* {isActiveTeam?.teamId !== 0.1 && (
                  <div
                    className={`side-nav__section__main__sub__sec ${
                      activeLink === "/airi-project-management" && "active"
                    }`}
                    style={{ cursor: "default" }}
                    onClick={() => handleActiveLink("/airi-project-management")}
                  >
                    <NavLink
                      to="/airi-project-management"
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                    >
                      {activeLink === "/airi-project-management" ? (
                        <img src={aiprojecticon} alt="ai-project-management" />
                      ) : (
                        <img src={aiprojecticon1} alt="ai-project-management" />
                      )}
                      <span>{t("ai_project_mangement")}</span>
                    </NavLink>
                  </div>
                )} */}
              </div>
            </div>
            <div className="side-nav__section__main__border"></div>
            <div className="side-nav__section__main">
              {/* <div className="side-nav__section__main__heading">
                <InfoIcon />
                <h4>{t("info")}</h4>
              </div> */}
              <div className="side-nav__section__main__sub">
                <div
                  className={`side-nav__section__main__sub__sec ${
                    activeLink === "/account" && "active"
                  }`}
                  onMouseEnter={(e) => handleMouseEnter(e, 5)}
                  onMouseLeave={() => handleMouseLeave(5)}
                  onClick={() => handleActiveLink("/account")}
                >
                  <NavLink
                    to="/account"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                  >
                    <AccountIcon
                      fill={activeLink === "/account" ? "#ffffff" : "#263238"}
                    />
                    <span>{t("account")}</span>
                  </NavLink>
                  {!responsiveData && (
                    <TooltipLeft
                      triggerElement={hoveredElement}
                      isVisible={hoverStatus[5]}
                      TooltipContent={t("account_tooltip")}
                      style={lang === "ch" ? 10 : 40}
                    />
                  )}
                </div>
                <div
                  className={`side-nav__section__main__sub__sec ${
                    activeLink === "/news" && "active"
                  }`}
                  onMouseEnter={(e) => handleMouseEnter(e, 6)}
                  onMouseLeave={() => handleMouseLeave(6)}
                  onClick={() => handleActiveLink("/news")}
                >
                  <NavLink
                    to="/news"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                  >
                    {teamMemberNotification === true && (
                      <div className="red-dot"></div>
                    )}
                    <NewsIcon
                      fill={activeLink === "/news" ? "#ffffff" : "#263238"}
                    />
                    <span>{t("news")}</span>
                  </NavLink>
                  {!responsiveData && (
                    <TooltipLeft
                      triggerElement={hoveredElement}
                      isVisible={hoverStatus[6]}
                      TooltipContent={t("news_tooltip")}
                      style={lang === "ch" ? 10 : 20}
                    />
                  )}
                </div>
                <div
                  className={`side-nav__section__main__sub__sec ${
                    activeLink === "/tutorials" && "active"
                  }`}
                  onMouseEnter={(e) => handleMouseEnter(e, 7)}
                  onMouseLeave={() => handleMouseLeave(7)}
                  onClick={() => handleActiveLink("/tutorials")}
                >
                  <NavLink
                    to="/tutorials"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                  >
                    <TutorialIcon
                      fill={activeLink === "/tutorials" ? "#ffffff" : "#263238"}
                    />
                    <span>{t("tutorial")}</span>
                    <h6>{t("new")}</h6>
                  </NavLink>
                  {!responsiveData && (
                    <TooltipLeft
                      triggerElement={hoveredElement}
                      isVisible={hoverStatus[7]}
                      TooltipContent={t("tutorial_tooltip")}
                      style={lang === "ch" ? 0 : 5}
                    />
                  )}
                </div>
                <div
                  className={`side-nav__section__main__sub__sec ${
                    activeLink === "/airi-faq" && "active"
                  }`}
                  onClick={() => handleActiveLink("/airi-faq")}
                >
                  <NavLink
                    to="/airi-faq"
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                  >
                    <AiriFAQIcon
                      fill={activeLink === "/airi-faq" ? "#ffffff" : "#263238"}
                    />
                    <span>
                      {clientData?.alias} {t("airi_faq")}
                    </span>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="side-nav__bottom">
            <div className="side-nav__bottom__copy">
              <NavLink to="/contact" style={{ textDecoration: "none" }}>
                <p>&#169; {clientData?.name}</p>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNavComponent;
