import React, { useContext, useEffect, useRef, useState } from "react";
import "./Workshop.scss";
import dots from "../../assets/images/teams/icons/dots.png";
import search from "../../assets/images/teams/icons/search.png";
import addicon from "../../assets/images/icons/add-icon1.png";
import addicon1 from "../../assets/images/icons/add-icon1d.png";
import deleteproject from "../../assets/images/icons/project-delete.png";
import renameproject from "../../assets/images/icons/rename.png";
import newtabproject from "../../assets/images/icons/new-tab.png";
import viewicon from "../../assets/images/teams/icons/view.png";
import dataicon from "../../assets/images/teams/icons/team-data.png";
import arrow from "../../assets/images/icons/arrow-down.png";
import back from "../../assets/images/icons/back-arrow1.png";
import loader from "../../assets/images/workspace/upscale-gif.gif";
import intro from "../../assets/images/workspace/icons/intro.png";
import exiticon from "../../assets/images/teams/icons/exit.png";
import manage from "../../assets/images/icons/manage.png";
import manage1 from "../../assets/images/icons/manage-a.png";
import teamProjects from "../../assets/images/icons/team-projects.png";
import myProjects from "../../assets/images/icons/my-projects.png";
import teamProjectsd from "../../assets/images/icons/team-projectsd.png";
import myProjectsd from "../../assets/images/icons/my-projectsd.png";
import UserPortalLayout from "../../components/UserPortalLayout/UserPortalLayout";
import SideNavComponent from "../../components/SideNav/SideNavComponent";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UserSocketContext } from "../../store/UserSocketData";
import { ToolsetDataContext } from "../../store/WorkSpaceToolsetData";
import { TeamDesignContext } from "../../store/TeamDesignData";
import { CurrentProjectContext } from "../../store/CurrentProjectData";
import {
  DeleteImagesGallery,
  ExitFromTeam,
  GetAllProjectsForCurrentUser,
  GetLatestProjectId,
  GetMyPlanPrivilegeSummary,
} from "../../utils/apiCalls";
import Confirm from "../../components/confirmModal/confirm";
import { Popover } from "antd";
import TeamMembers from "../../components/WorkshopTeams/TeamMembers/TeamMembers";
import WorkshopTeam from "../../components/WorkshopTeams/WorkshopTeams";
import Loader from "../../components/loader";
import { UserContext } from "../../store/UserDetailsStore";
import EditProject from "../../components/WorkshopTeams/EditProject/EditProject";
import CreateProject from "../../components/WorkshopTeams/CreateProject/CreateProject";
import { convertDateTimeBefore } from "../../utils/workspaceUtils";

const WorkShop = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const ProjectsApiRef = useRef(false);

  const { updateSocketData } = useContext(UserSocketContext);
  const { updateToolsetData } = useContext(ToolsetDataContext);
  const { updateCurrentProjectData } = useContext(CurrentProjectContext);
  const {
    isTeamChanges,
    teamsList,
    isLoader,
    handleTeamChanges,
    getAllUserTeams,
    getTeamsOrder,
    setIsLoader,
  } = useContext(TeamDesignContext);
  const { soloTeamData, allTeamData, getUserAllPrivilages, isSmallScreen } =
    useContext(UserContext);

  const latestProjectId = localStorage.getItem("latestProjectId");
  const isActiveTeam = JSON.parse(localStorage.getItem("activeTeam"));
  const isWorkshop = localStorage.getItem("isWorkshop");
  const baseUrl = window.location.origin;
  const loginUserData = JSON.parse(localStorage.getItem("isLoginUser"));
  const isActiveLanguage = localStorage.getItem("language");

  const [searchProject, setSearchProject] = useState("");
  const [projectsList, setProjectsList] = useState([]);
  const [filterProjectsList, setFilterProjectsList] = useState([]);
  const [myProjectsList, setMyProjectsList] = useState([]);
  const [isShowLoader, setIsShowLoader] = useState(true);
  const [isDeleteData, setIsDeleteData] = useState();
  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isAlertMessage, setIsAlertMessage] = useState("");
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isExpand, setIsExapnd] = useState(false);
  const [showTeamMembers, setIsShowTeamMembers] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState();
  const [openPopoverId, setOpenPopoverId] = useState(null);
  const [openPopoverId1, setOpenPopoverId1] = useState(null);
  const [selectedTeamDetails, setSelectedTeamDetails] = useState(null);
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [privilegeSummary, setPrivilegeSummary] = useState(null);
  const [exitTeamData, setExitTeamData] = useState(null);
  const [exitTeam, setExitTeam] = useState(false);
  const [exitConfirm, setExitConfirm] = useState(false);
  const [isLoader1, setIsLoader1] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [editProjectPopup, setEditProjectPopup] = useState(false);
  const [editProjectData, setEditProjectData] = useState(null);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const initTeams = () =>{
    getTeamsOrder();
    getAllUserTeams();
  }

  useEffect(() => {
    initTeams();
  }, []);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  useEffect(() => {
    if (isWorkshop === null || isWorkshop === undefined) {
      localStorage.setItem("isWorkshop", false);
    }
  }, [isWorkshop]);

  useEffect(() => {
    ProjectsApiRef.current = false;
    if (!ProjectsApiRef.current) {
      getAllProjectsForUser();
      getLatestProject();
      getPrivilegeSummary();
      getUserAllPrivilages(
        isActiveTeam?.teamId !== 0.1 ? isActiveTeam?.teamId : 0
      );
    }
  }, [isTeamChanges]);

  useEffect(() => {
    if (soloTeamData) {
      if (teamsList?.length !== 0) {
        if (isActiveTeam?.teamId !== 0.1) {
          setIsLoader(true);
          // setFilteredTeams([]);
          const removesoloTeam = teamsList?.filter(
            (team) => team?.teamId !== 0.1
          );

          let removeSelectedTeam = removesoloTeam?.filter(
            (team) => team?.teamId !== isActiveTeam?.teamId
          );
          removeSelectedTeam?.splice(0, 0, isActiveTeam);
          removeSelectedTeam?.splice(0, 0, soloTeamData);

          setFilteredTeams(removeSelectedTeam);
          setIsLoader(false);
        } else {
          setIsLoader(true);
          let data = teamsList;
          const removesoloTeam = data?.filter(
            (team) => team?.teamId !== 0.1
          );

          const active = data?.filter((team) => team?.teamId === 0.1);

          if (active?.length === 0) {
            removesoloTeam?.splice(0, 0, soloTeamData);
            setFilteredTeams(removesoloTeam);
          } else {
            setFilteredTeams(data);
          }
          setIsLoader(false);
        }
      } else {
        // if (!isLoader) { // might be problematic
          const data = []; 
          data?.splice(0, 0, soloTeamData);
          setFilteredTeams(data);
        // }
      }
    }
  }, [isTeamChanges, teamsList, soloTeamData]);

  useEffect(() => {
    if (projectsList?.length !== 0 || myProjectsList?.length !== 0) {
      const delay = 0;
      let timeoutId;

      const debounceSearch = () => {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
          if (isActiveTeam?.teamId !== 0.1) {
            if (activeTab === 1) {
              getFilterTeams(searchProject);
            } else {
              getFilterMyTeams(searchProject);
            }
          } else {
            getFilterMyTeams(searchProject);
          }
        }, delay);
      };

      debounceSearch();

      return () => {
        clearTimeout(timeoutId);
      };
    } else{
      if(activeTab === 2){
        getFilterMyTeams(searchProject);
      }else{
        getFilterTeams(searchProject);
      }
    }
  }, [searchProject, projectsList, myProjectsList, activeTab]);

  const getFilterTeams = (name) => {
    const lowerCaseName = name?.toLowerCase();
    const data = projectsList?.filter((team) =>
      team?.name?.toLowerCase()?.includes(lowerCaseName)
    );
    setFilterProjectsList(data);
  };

  const getFilterMyTeams = (name) => {
    const lowerCaseName = name?.toLowerCase();
    const data = myProjectsList?.filter((team) =>
      team?.name?.toLowerCase()?.includes(lowerCaseName)
    );
    setFilterProjectsList(data);
  };

  const handleClick = (id) => {
    setActiveTab(id);
    setIsShowLoader(true);
  };

  useEffect(() => {
      setTimeout(() => {
        setIsShowLoader(false);
      }, 1000);
  }, [filterProjectsList]);

  const getPrivilegeSummary = () => {
    GetMyPlanPrivilegeSummary((res) => {
      if (res?.status === 200) {
        setPrivilegeSummary(res?.data);
      } else {
        setPrivilegeSummary(null);
      }
    });
  };

  const getLatestProject = () => {
    const teamId = isActiveTeam?.teamId !== 0.1 ? isActiveTeam?.teamId : 0;
    GetLatestProjectId((res) => {
      if (res?.status === 200) {
        localStorage.setItem("getLatestProjectId", res?.data?.projectId);
      }
      if (res?.message === "Invalid access token") {
        updateSocketData({
          isExitPopup: true,
        });
      }
    }, teamId);
  };

  const getAllProjectsForUser = () => {
    const teamId = isActiveTeam?.teamId !== 0.1 ? isActiveTeam?.teamId : 0;
    GetAllProjectsForCurrentUser((res) => {
      if (res?.status === 200) {
        // const combinedProjects = res?.userData.projectModel.concat(
        //   res?.teamData.projectModel
        // );
        setProjectsList(res?.teamData.projectModel);
        setMyProjectsList(res?.userData.projectModel);
        if (
          res?.userData?.projectModel?.length !== 0 &&
          res?.userData?.projectModel?.length !== undefined
        ) {
          const projectsForStorage = res?.userData?.projectModel[0]?.userId;
          const projectsJson = JSON.stringify(projectsForStorage);
          localStorage.setItem("currentProjectsUserId", projectsJson);
          if (
            latestProjectId === "undefined" ||
            latestProjectId === null ||
            latestProjectId === "null"
          ) {
            if (res?.userData?.projectCount > 0) {
              let count = res?.userData?.projectModel?.length - 1;
              updateToolsetData({
                isSetLatestProjectCreated: count,
              });
              const latestProjectCreated =
                res?.userData?.projectModel[count]?.id;
              localStorage.setItem("latestProjectId", latestProjectCreated);
            }
          }
        }
      } else if (res?.message === "Invalid access token") {
        updateSocketData({
          isExitPopup: true,
        });
      } else {
        setProjectsList([]);
        setMyProjectsList([]);
      }
    }, teamId);
  };

  const handleExpand = () => {
    setIsExapnd(!isExpand);
  };

  async function handleProjecWorkFlow(id) {
    if (latestProjectId !== null) {
      navigate(`/workspace/${id}`);
      updateCurrentProjectData({
        projectName: "",
        event: handleEditProject,
        isShowLoadingCard: true,
     }); 
    }
  }

  const handleDeleteProjectPopup = (project) => {
    setOpenPopoverId1(null);
    setIsDeleteData(project);
    setIsDeletePopup(true);
  };

  const handleCloseDelete = () => {
    setIsDeletePopup(false);
  };

  const handleDeleteProject = (id) => {
    setIsLoader1(true);
    const payload = {
      projectId: id,
      mediaIds: [],
    };
    if (payload) {
      DeleteImagesGallery((res) => {
        setIsDeletePopup(false);
        const { status, message } = res;
        if (status === 200) {
          getAllProjectsForUser();
          getLatestProject();
          setIsLoader1(false);
          setIsDeletePopup(false);
          setIsAlertMessage("Project deleted sucessfully");
          setIsConfirmModal(true);
        } else {
          setIsLoader1(false);
          setIsAlertMessage(message);
          setIsConfirmModal(true);
        }
        if (res?.message === "Invalid access token") {
          updateSocketData({
            isExitPopup: true,
          });
        }
      }, payload);
    }
  };

  const handleOpen = (isOpen) => {
    setOpen(isOpen);
  };

  const handleOpen1 = (isOpen) => {
    setOpen1(isOpen);
  };

  const handleOpen2 = (isOpen) => {
    setOpen2(isOpen);
  };

  const handleOpen3 = (isOpen) => {
    setOpen3(isOpen);
  };

  const handleOpenChange = (teamId, isOpen) => {
    if (isOpen) {
      setOpenPopoverId(teamId);
    } else {
      setOpenPopoverId(null);
    }
  };

  const handleOpenChange1 = (teamId, isOpen) => {
    if (isOpen) {
      setOpenPopoverId1(teamId);
    } else {
      setOpenPopoverId1(null);
    }
  };

  const handleTeamMembers = (team) => {
    setOpenPopoverId(null);
    setSelectedTeam(team);
    setIsShowTeamMembers(true);
  };

  const handleCloseteamMembers = () => {
    setIsShowTeamMembers(false);
  };

  const handleManageTeam = (team) => {
    localStorage.setItem("isWorkshop", true);
    setOpenPopoverId(null);
    setSelectedTeamDetails(team);
  };

  const handleBack = () => {
    localStorage.setItem("isWorkshop", false);
    if (selectedTeamDetails){
      setSelectedTeamDetails(null);
    }else{
      setSelectedTeamDetails('temporary');
      setTimeout(() => setSelectedTeamDetails(null), 0);
    }
  };

  const handleTeamChange = (team) => {
    setActiveTab(1);
    setIsShowLoader(true);
    setIsExapnd(false);
    localStorage.setItem("activeTeam", JSON.stringify(team));
    handleTeamChanges();
    initTeams();
  };

  const handleExit = (team) => {
    setOpenPopoverId(null);
    setExitTeamData(team);
    setExitTeam(true);
  };

  const handleExitTeam = () => {
    setIsLoader1(true);
    setExitTeam(false);
    const payload = {
      teamId: exitTeamData?.teamId,
    };
    ExitFromTeam((res) => {
      if (res?.status === 200) {
        setIsLoader1(false);
        setExitConfirm(true);
        initTeams();
        if (isActiveTeam?.teamId === exitTeamData?.teamId) {
          localStorage.setItem("activeTeam", JSON.stringify(soloTeamData));
        }
      }
    }, payload);
  };

  const handleExitConfirmClose = () => {
    setExitConfirm(false);
  };

  const handleProjectNewTab = (project) => {
    setOpenPopoverId1(null);
    window.open(`${baseUrl}/workspace/${project?.id}`, "_blank");
  };

  const handleEditProject = (project = null) => {
    setOpenPopoverId1(null);
    setEditProjectData(project);
    setEditProjectPopup(true);
  };

  const handleEditProjectClose = () => {
    setEditProjectPopup(false);
  };

  const handleCreateProjectClose = () => {
    setOpen(null);
  };

  const displayEmptyContent = () =>{
    if (searchProject){
      return (
        <>
          <h4>{t("empty_projectSearch_size_text1")}</h4>
          <p>
            {t("empty_projectSearch_size_text2")} "
            {searchProject}"{" "}
          </p>
        </>
      )
    }else{
      return(
        <>
          <h4>{t("empty_project_size_text1")}</h4>
          <p>
            {t("empty_project_size_text2")} "
            {t("empty_project_size_text3")}"{" "}
            {t("empty_project_size_text4")}
          </p>
        </>
      )
    }
  }

  const deleteTitle = `${t("delete")}  ${isDeleteData?.name}`;
  const deleteContent = `${t("project_delete")} "${isDeleteData?.name}"?`;
  const teamExitText = `${t("exit_team_text")} ${exitTeamData?.teamName}?`;
  const confirmExitText = `${t("exit_team_success")} ${
    exitTeamData?.teamName
  } ${t("exit_team_success1")}`;
  const content = t("generation_count_content");
  const contentLines = content?.split("/n");
  const content1 = t("member_limit_content");
  const contentLines1 = content1?.split("/n");
  const content2 = t("free_generation_count_content");
  const contentLines2 = content2?.split("/n");

  return (
    <UserPortalLayout>
      <div className="workshop">
        <SideNavComponent />
        <div className="workshop__container" data-scrollable="true">
          {isWorkshop === "false" ? (
            <div className="workshop__section">
              <div className="workshop__section__teams">
                <div className="workshop__section__teams__list">
                  <div className="workshop__section__teams__list__expand">
                     {filteredTeams?.length !== 0 ? (
                      <div
                        className={`workshop__section__teams__list__cards ${
                          isExpand ? "show-more" : ""
                        }`}
                      >
                        {filteredTeams?.map((team, index) => {
                          const isActiveTeam = JSON.parse(
                            localStorage.getItem("activeTeam")
                          );
                          const active = isActiveTeam?.teamId === team.teamId;
                          const teamName =
                            team?.teamId !== 0.1
                              ? team?.teamName
                              : t("my_space");
                          const openId = openPopoverId === team?.teamId;
                          return (
                            <div
                              className={`workshop__section__teams__list__card ${
                                active ? "active" : ""
                              } ${openId ? "hovered" : ""}`}
                              key={index}
                              onClick={() => handleTeamChange(team)}
                            >
                              <div className="workshop__section__teams__list__card__container">
                                <div className="workshop__section__teams__list__card__left">
                                  <div className="workshop__section__teams__list__card__icon">
                                    <img
                                      src={team?.teamIcon}
                                      alt="user-avatar"
                                    />
                                  </div>
                                  <div className="workshop__section__teams__list__card__name">
                                    <h2>{teamName}</h2>
                                    <h4>{team?.username}</h4>
                                  </div>
                                </div>
                                <div className="workshop__section__teams__list__card__right">
                                  {!isSmallScreen && team?.teamId !== 0.1 && (
                                    <div
                                      className="workshop__section__teams__list__card__dots"
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <Popover
                                        overlayClassName="custom-popover2"
                                        content={
                                          <>
                                            <div
                                              className="workshop__section__teams__list__card__options__sec"
                                              onClick={() =>
                                                handleTeamMembers(team)
                                              }
                                            >
                                              <img
                                                src={dataicon}
                                                alt="view-icon"
                                              />
                                              <h5>{t("members")}</h5>
                                            </div>
                                            {team?.roleId === 1 ? (
                                              <div
                                                className="workshop__section__teams__list__card__options__sec"
                                                onClick={() =>
                                                  handleManageTeam(team)
                                                }
                                              >
                                                <img
                                                  src={viewicon}
                                                  alt="view-icon"
                                                />
                                                <h5>{t("manage")}</h5>
                                              </div>
                                            ) : (
                                              <div
                                                className="workshop__section__teams__list__card__options__sec"
                                                onClick={() => handleExit(team)}
                                              >
                                                <img
                                                  src={exiticon}
                                                  alt="view-icon"
                                                />
                                                <h5>{t("exit_team")}</h5>
                                              </div>
                                            )}
                                          </>
                                        }
                                        arrow={false}
                                        trigger="click"
                                        placement="bottomRight"
                                        open={openPopoverId === team?.teamId}
                                        onOpenChange={(isOpen) =>
                                          handleOpenChange(team?.teamId, isOpen)
                                        }
                                      >
                                        <img
                                          className="card-dots"
                                          src={dots}
                                          alt="dots-icon"
                                        />
                                      </Popover>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="workshop__section__teams__loader">
                        {isLoader ? (
                          <img src={loader} alt="loader-gif" />
                        ) : (
                          <p>{t("empty_team_size_text")}</p>
                        )}
                      </div>
                    )} 
                    {filteredTeams?.length > 3 && (
                      <div
                        className="workshop__section__teams__list__expand__sec"
                        onClick={handleExpand}
                      >
                        <img
                          className={`${isExpand ? "expanded" : ""}`}
                          src={arrow}
                          alt="exapnd-icon"
                        />
                        <h5>{t("expand")}</h5>
                      </div>
                    )}
                  </div>
                  <div className="workshop__section__teams__list__button">
                    <button className={`${isActiveLanguage === "ch" ? "center" : ""}`} onClick={() => handleManageTeam(allTeamData)}>
                      <img
                        className="manage-default"
                        src={manage}
                        alt="manage-tams-icon"
                      />
                      <img
                        className="manage-active"
                        src={manage1}
                        alt="manage-tams-icon"
                      />
                      <span>{t("manage_my_team")}</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="workshop__section__projects">
                <div className="workshop__section__projects__container">
                  <div className="workshop__section__projects__header">
                    <div className="workshop__section__projects__header__top">
                      {isActiveTeam?.teamId === 0.1 ? (
                        <h2>{t("my_projects")}</h2>
                      ) : (
                        <h2>
                          {isActiveTeam?.teamName} {t("projects")}
                        </h2>
                      )}
                    </div>
                    <div className="workshop__section__projects__header__bottom">
                      <div className="workshop__section__projects__header__left">
                        {isActiveTeam?.teamId !== 0.1 && (
                          <div
                            className={`workshop__section__projects__header__left__sec ${
                              activeTab === 1 ? "active" : ""
                            }`}
                            onClick={() => handleClick(1)}
                          >
                            <div className="workshop__section__projects__header__left__sec__icon">
                              <img
                                className="active"
                                src={teamProjects}
                                alt="team-projects-icon"
                              />
                              <img
                                className="default"
                                src={teamProjectsd}
                                alt="team-projects-icon"
                              />
                            </div>
                            <div className="workshop__section__projects__header__left__sec__name">
                              <h4
                                className={`${activeTab === 1 ? "active" : ""}`}
                              >
                                {t("all_projects")}
                              </h4>
                            </div>
                          </div>
                        )}
                        {isActiveTeam?.teamId !== 0.1 && (
                          <div
                            className={`workshop__section__projects__header__left__sec ${
                              activeTab === 2 ? "active" : ""
                            }`}
                            onClick={() => handleClick(2)}
                          >
                            <div className="workshop__section__projects__header__left__sec__icon">
                              <img
                                className="active"
                                src={myProjects}
                                alt="my-projects-icon"
                              />
                              <img
                                className="default"
                                src={myProjectsd}
                                alt="my-projects-icon"
                              />
                            </div>
                            <div className="workshop__section__projects__header__left__sec__name">
                              <h4
                                className={`${activeTab === 2 ? "active" : ""}`}
                              >
                                {t("projects_joined")}
                              </h4>
                            </div>
                          </div>
                        )}
                        <div className="workshop__section__projects__header__left__sec">
                          <div className="workshop__section__projects__header__left__sec__search">
                            <input
                              placeholder={t("search_project_name")}
                              value={searchProject}
                              onChange={(e) => {
                                const text = e.target.value;
                                const newText = text.replace(/\s+/g, " ");
                                setSearchProject(newText);
                              }}
                            />
                            <img src={search} alt="search-icon" />
                          </div>
                        </div>
                      </div>
                      <div className="workshop__section__projects__header__right">
                        <div className="workshop__section__projects__header__right__sec">
                          <div className="workshop__section__projects__header__right__sec__button">
                            {isSmallScreen ? (
                              <button onClick={() => handleEditProject()}>
                                <img
                                  className="add-default"
                                  src={addicon}
                                  alt="add-icon"
                                />
                                <img
                                  className="add-active"
                                  src={addicon1}
                                  alt="add-icon"
                                />
                                <span>{t("create_project")}</span>
                              </button>
                            ) : (
                              <Popover
                                overlayClassName="project-popover"
                                content={
                                  <CreateProject
                                    onClose={handleCreateProjectClose}
                                    setIsLoader1={setIsLoader1}
                                    isLoader1={isLoader1}
                                    open={open}
                                    filterProjectsList={filterProjectsList}
                                  />
                                }
                                arrow={false}
                                trigger="click"
                                placement="bottomRight"
                                open={open}
                                onOpenChange={handleOpen}
                              >
                                <button>
                                  <img
                                    className="add-default"
                                    src={addicon}
                                    alt="add-icon"
                                  />
                                  <img
                                    className="add-active"
                                    src={addicon1}
                                    alt="add-icon"
                                  />
                                  <span>{t("create_project")}</span>
                                </button>
                              </Popover>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="workshop__section__projects__main">
                    {isShowLoader 
                      ? (
                        <div className="workshop__section__projects__loader">
                          <img src={loader} alt="loader-gif" />
                        </div>
                      ) : filterProjectsList?.length !== 0 ? (
                          <div className="workshop__section__projects__list">
                            {filterProjectsList?.map((project, index) => {
                              const dateString =
                                project?.projectMediaModel?.length !== 0
                                  ? project?.projectMediaModel[0]?.createdAt
                                  : project?.createdAt;
    
                              const date = new Date(dateString);
                              const formattedDate = convertDateTimeBefore(date);
                              const openId = openPopoverId1 === project?.id;
                              const isUserCreated =
                                project?.userId === loginUserData?.id;
                              return (
                                <div
                                  className={`workshop__section__projects__card ${
                                    openId ? "hovered" : ""
                                  }`}
                                  key={index}
                                  onClick={
                                    isShowLoader
                                      ? null
                                      : () => handleProjecWorkFlow(project.id)
                                  }
                                >
                                  <div className="workshop__section__projects__card__container">
                                    <div className="workshop__section__projects__card__section">
                                      <div
                                        className={`workshop__section__projects__card__section__images ${
                                          project?.projectMediaModel?.length === 1
                                            ? "single"
                                            : ""
                                        }`}
                                      >
                                        {project?.projectMediaModel?.map(
                                          (images, index) => {
                                            return (
                                              <img
                                                key={index}
                                                className={`img ${
                                                  project?.projectMediaModel
                                                    ?.length === 1
                                                    ? "single"
                                                    : ""
                                                }`}
                                                src={images?.thumbnail}
                                                alt={images?.id}
                                              />
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                    <div className="workshop__section__projects__card__content">
                                      <div className="workshop__section__projects__card__content__name">
                                        <h4>{project?.name}</h4>
                                      </div>
                                      <div className="workshop__section__projects__card__content__data">
                                        <span>{formattedDate}</span>
                                        {isActiveTeam?.teamId !== 0.1 ? (
                                          <div className="workshop__section__projects__card__content__data__right">
                                            <div className="workshop__section__projects__card__content__data__users">
                                              <img
                                                  key={0}
                                                  src={project?.avatarUrl}
                                                  alt="user-icon"
                                                  style={{
                                                    zIndex: `0`,
                                                  }}
                                                />  
                                              {project?.userGenerated?.map(
                                                (avtar, index) => {
                                                  return (
                                                    <img
                                                      key={index + 1}
                                                      src={avtar?.avatarUrl}
                                                      alt="user-icon"
                                                      style={{
                                                        left: `-${(index + 1) * 35}%`,
                                                        zIndex: `${10 + index + 1}`,
                                                      }}
                                                    />
                                                  );
                                                }
                                              )}
                                            </div>
                                            <div
                                              className="workshop__section__projects__card__content__data__dots"
                                              onClick={(e) => e.stopPropagation()}
                                            >
                                              <Popover
                                                overlayClassName="custom-popover5"
                                                content={
                                                  <>
                                                    <div
                                                      className="workshop__section__projects__card__content__data__dots__sec"
                                                      onClick={() =>
                                                        handleProjectNewTab(project)
                                                      }
                                                    >
                                                      <img
                                                        src={newtabproject}
                                                        alt="view-icon"
                                                      />
                                                      <h5>
                                                        {t("open_in_new_tab")}
                                                      </h5>
                                                    </div>
                                                    {(isUserCreated || isActiveTeam?.userId === loginUserData?.id) && (
                                                      <>
                                                        <div
                                                          className="workshop__section__projects__card__content__data__dots__sec"
                                                          onClick={() =>
                                                            handleEditProject(
                                                              project
                                                            )
                                                          }
                                                        >
                                                          <img
                                                            src={renameproject}
                                                            alt="view-icon"
                                                          />
                                                          <h5>{t("rename")}</h5>
                                                        </div>
                                                        <div
                                                          className="workshop__section__projects__card__content__data__dots__sec"
                                                          onClick={() =>
                                                            handleDeleteProjectPopup(
                                                              project
                                                            )
                                                          }
                                                        >
                                                          <img
                                                            src={deleteproject}
                                                            alt="view-icon"
                                                          />
                                                          <h5>
                                                            {t("delete_project")}
                                                          </h5>
                                                        </div>
                                                      </>
                                                    )}
                                                  </>
                                                }
                                                arrow={false}
                                                trigger="click"
                                                placement="bottomRight"
                                                open={
                                                  openPopoverId1 === project?.id
                                                }
                                                onOpenChange={(isOpen) =>
                                                  handleOpenChange1(
                                                    project?.id,
                                                    isOpen
                                                  )
                                                }
                                              >
                                                <img
                                                  className="card-dots"
                                                  src={dots}
                                                  alt="dots-icon"
                                                />
                                              </Popover>
                                            </div>
                                          </div>
                                        ) : (
                                          <div
                                            className="workshop__section__projects__card__content__data__dots card-dots"
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <Popover
                                              overlayClassName="custom-popover5"
                                              content={
                                                <>
                                                  <div
                                                    className="workshop__section__projects__card__content__data__dots__sec"
                                                    onClick={() =>
                                                      handleProjectNewTab(project)
                                                    }
                                                  >
                                                    <img
                                                      src={newtabproject}
                                                      alt="view-icon"
                                                    />
                                                    <h5>{t("open_in_new_tab")}</h5>
                                                  </div>
                                                  <div
                                                    className="workshop__section__projects__card__content__data__dots__sec"
                                                    onClick={() =>
                                                      handleEditProject(project)
                                                    }
                                                  >
                                                    <img
                                                      src={renameproject}
                                                      alt="view-icon"
                                                    />
                                                    <h5>{t("rename")}</h5>
                                                  </div>
                                                  <div
                                                    className="workshop__section__projects__card__content__data__dots__sec"
                                                    onClick={() =>
                                                      handleDeleteProjectPopup(
                                                        project
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src={deleteproject}
                                                      alt="view-icon"
                                                    />
                                                    <h5>{t("delete_project")}</h5>
                                                  </div>
                                                </>
                                              }
                                              arrow={false}
                                              trigger="click"
                                              placement="bottomRight"
                                              open={openPopoverId1 === project?.id}
                                              onOpenChange={(isOpen) =>
                                                handleOpenChange1(
                                                  project?.id,
                                                  isOpen
                                                )
                                              }
                                            >
                                              <img
                                                className="card-dots"
                                                src={dots}
                                                alt="dots-icon"
                                              />
                                            </Popover>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <div className="workshop__section__projects__loader">
                            {displayEmptyContent()}
                          </div>
                            )    
                    }           
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="workshop__section">
              <div className="workshop__section__main">
                <div className="workshop__section__main__button">
                  <button onClick={handleBack}>
                    <img src={back} alt="back-arrow" />{" "}
                    <h6>{t("team_back")}</h6>
                  </button>
                </div>
                <div className="workshop__section__main__privilege">
                  <div className="workshop__section__main__privilege__details">
                    <div className="workshop__section__main__privilege__details__heading">
                      <h2>{t("privilege_summary")}</h2>
                    </div>
                    <div className="workshop__section__main__privilege__details__content">
                      <div className="workshop__section__main__privilege__details__content__sec">
                        <div className="workshop__section__main__privilege__details__content__sec__sub">
                          <h3>{t("free_generation_count")}</h3>
                          <Popover
                            overlayClassName="question-popover"
                            content={
                              <div className="popover-content">
                                {contentLines2?.map((text, index) => {
                                  return (
                                    <p className="popover-text" key={index}>
                                      {text}
                                    </p>
                                  );
                                })}
                              </div>
                            }
                            arrow={true}
                            trigger="click"
                            placement="right"
                            open={open3}
                            onOpenChange={handleOpen3}
                          >
                            <img src={intro} alt="question-mark-icon" />
                          </Popover>
                        </div>
                        <h4 style = {{
                            color: privilegeSummary?.userGenLeftCount <= 0 ? 'red' : 'inherit'
                        }}>
                          {privilegeSummary?.dailyGenLeft || 0}/
                          {privilegeSummary?.totalGenDaily || 0}
                        </h4>
                      </div>
                      <div className="workshop__section__main__privilege__details__content__sec">
                        <div className="workshop__section__main__privilege__details__content__sec__sub">
                          <h3>{t("generation_count")}</h3>
                          <Popover
                            overlayClassName="question-popover"
                            content={
                              <div className="popover-content">
                                {contentLines?.map((text, index) => {
                                  return (
                                    <p className="popover-text" key={index}>
                                      {text}
                                    </p>
                                  );
                                })}
                              </div>
                            }
                            arrow={true}
                            trigger="click"
                            placement="right"
                            open={open2}
                            onOpenChange={handleOpen2}
                          >
                            <img src={intro} alt="question-mark-icon" />
                          </Popover>
                        </div>
                        <h4 style = {{
                            color: privilegeSummary?.userGenLeftCount <= 0 ? 'red' : 'inherit'
                        }}>
                          {(privilegeSummary?.totalGenMasterCount || 0) - (privilegeSummary?.userGenLeftCount || 0)}/
                          {privilegeSummary?.totalGenMasterCount || 0}
                        </h4>
                      </div>
                      <div className="workshop__section__main__privilege__details__content__sec">
                        <div className="workshop__section__main__privilege__details__content__sec__sub">
                          <h3>{t("member_limit")}</h3>
                          <Popover
                            overlayClassName="question-popover"
                            content={
                              <div className="popover-content">
                                {contentLines1?.map((text, index) => {
                                  return (
                                    <p className="popover-text" key={index}>
                                      {text}
                                    </p>
                                  );
                                })}
                              </div>
                            }
                            arrow={true}
                            trigger="click"
                            placement="right"
                            open={open1}
                            onOpenChange={handleOpen1}
                          >
                            <img src={intro} alt="question-mark-icon" />
                          </Popover>
                        </div>
                        <h4
                          style={{
                            color:
                              privilegeSummary?.userMemberLeftCount <= 0
                                ? "red"
                                : "inherit",
                          }}
                        >
                          {Math.max((privilegeSummary?.memberMasterCount || 0) -
                            (privilegeSummary?.userMemberLeftCount || 0),1)}
                          {""}/
                          {Math.max(privilegeSummary?.memberMasterCount || 0 , 1)}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <WorkshopTeam
                  selectedTeamDetails={selectedTeamDetails}
                  teams={filteredTeams}
                  privilegeSummary={privilegeSummary}
                  getPrivilegeSummary={getPrivilegeSummary}
                />
              </div>
            </div>
          )}
          {isDeletePopup && (
            <Confirm
              isCancelRequired={true}
              confirmTitle={deleteTitle}
              content={deleteContent}
              onConfirm={() => handleDeleteProject(isDeleteData?.id)}
              onCancel={handleCloseDelete}
              cancelButtonText={t("cancel")}
              buttonText={t("delete")}
            />
          )}
        </div>
        {isConfirmModal && (
          <Confirm
            buttonText={"OK"}
            isCancelRequired={false}
            confirmTitle={isAlertMessage}
            onConfirm={() => {
              setIsConfirmModal(false);
            }}
            onCancel={() => {
              setIsConfirmModal(false);
            }}
          />
        )}
        {showTeamMembers && (
          <TeamMembers team={selectedTeam} onClose={handleCloseteamMembers} />
        )}
        {editProjectPopup && (
          <EditProject
            project={editProjectData}
            onClose={handleEditProjectClose}
            setIsLoader1={setIsLoader1}
            isLoader1={isLoader1}
            filterProjectsList={filterProjectsList}
            setFilterProjectsList={setFilterProjectsList}
          />
        )}
        {exitTeam && (
          <Confirm
            buttonText={t("confirm")}
            isCancelRequired={true}
            confirmTitle={t("exit_team_heading")}
            content={teamExitText}
            onConfirm={handleExitTeam}
            onCancel={() => setExitTeam(false)}
            cancelButtonText={t("cancel")}
          />
        )}
        {exitConfirm && (
          <Confirm
            buttonText={t("ok")}
            isCancelRequired={false}
            confirmTitle={t("congratulations")}
            content={confirmExitText}
            onConfirm={handleExitConfirmClose}
            onCancel={handleExitConfirmClose}
          />
        )}
        {isLoader1 && <Loader />}
      </div>
    </UserPortalLayout>
  );
};

export default WorkShop;
