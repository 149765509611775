import React, { useContext, useEffect, useState } from "react";
import "./WorkshopTeams.scss";
import arrow from "../../assets/images/icons/team-list-arrow.png";
import arrowa from "../../assets/images/teams/icons/team-list-arrowa.png";
import search from "../../assets/images/teams/icons/search.png";
import soloicon from "../../assets/images/teams/icons/team-icon-solo.png";
import soloicona from "../../assets/images/teams/icons/team-icon-solo_a.png";
import teamicond from "../../assets/images/teams/icons/team-icon-default.png";
import teamicona from "../../assets/images/teams/icons/team-icon-active.png";
import deleteicon from "../../assets/images/icons/delete.png";
import editicon from "../../assets/images/teams/icons/edit.png";
import dots from "../../assets/images/teams/icons/dots.png";
import loader from "../../assets/images/workspace/upscale-gif.gif";
import { useTranslation } from "react-i18next";
import TeamMemberDetails from "./TeamMemberDetails/TeamMemberDetails";
import TeamdataDetails from "./teamDataDetails/TeamdataDetails";
import AddExistingMember from "./AddExistingMember/AddExistingMember";
import { TeamDesignContext } from "../../store/TeamDesignData";
import AddNewMember from "./AddNewMember/AddNewMember";
import { Popover } from "antd";
import CreateOrEditTeam from "./CreateOrEditTeam/CreateOrEditTeam";
import { DeleteUserTeam } from "../../utils/apiCalls";
import Confirm from "../confirmModal/confirm";
import Loader from "../loader";
import { UserContext } from "../../store/UserDetailsStore";
import { UserSocketContext } from "../../store/UserSocketData";
import { useNavigate } from "react-router-dom";

const WorkshopTeam = ({
  selectedTeamDetails,
  teams,
  privilegeSummary,
  getPrivilegeSummary,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getAllUserTeams } = useContext(TeamDesignContext);
  const { soloTeamData, allTeamData } = useContext(UserContext);
  const { handleShowSubscription } = useContext(UserSocketContext);
  const [searchTeam, setSearchTeam] = useState("");
  const [activeTab, setActiveTab] = useState(1);
  const [teamData, setTeamData] = useState(selectedTeamDetails ?? allTeamData);
  const [filterTeamList, setFilterTeamList] = useState([]);
  const [showExistingTeamMembers, setShowExistingTeamMembers] = useState(false);
  const [showInviteTeamMembers, setShowInviteTeamMembers] = useState(false);
  const [openPopoverId, setOpenPopoverId] = useState(null);
  const [createTeamPopup, setCreateTeamPopup] = useState(false);
  const [editTeamData, setEditTeamData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoader1, setIsLoader1] = useState(false);
  const [deleteTeam, setDeleteTeam] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [exitTeam, setExitTeam] = useState(null);
  const [isShowLoader, setIsShowLoader] = useState(false);
  const [teamCreated, setTeamCreated] = useState(false);
  const [isInvited, setIsInvited] = useState(false);
  const [teamDetailsUpdateNeeded, setIsTeamDetailsUpdateNeeded] =
    useState(false);
  const [isTeamLimitPoup, setIsTeamLimitPopup] = useState(false);
  const [isInviteLimitPoup, setIsInviteLimitPopup] = useState(false);

  useEffect(() => {
    if (teams?.length !== 0) {
      const data = teams?.filter((team) => team?.roleId === 1);
      setFilterTeamList(data);
    }
  }, [teams]);

  useEffect(() => {
    const delay = 1000;
    let timeoutId;

    const debounceSearch = () => {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        // setFilterTeamList([]);
        setIsShowLoader(true);
        getFilterTeams(searchTeam);
      }, delay);
    };

    debounceSearch();

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchTeam, teams]);

  const getFilterTeams = (name) => {
    const lowerCaseName = name?.toLowerCase();
    const data = teams?.filter(
      (team) =>
        team?.teamName?.toLowerCase()?.includes(lowerCaseName) &&
        team?.roleId === 1
    );
    setFilterTeamList(data);
    setIsShowLoader(false);
  };

  const handleTab = (id) => {
    setActiveTab(id);
  };

  const handleChangeTeam = (team) => {
    setTeamData(team);
  };

  const handleExistingMember = () => {
    setShowExistingTeamMembers(!showExistingTeamMembers);
  };

  const handleNewMember = () => {
    setShowInviteTeamMembers(!showInviteTeamMembers);
  };

  const handleOpenChange = (teamId, isOpen) => {
    if (isOpen) {
      setOpenPopoverId(teamId);
    } else {
      setOpenPopoverId(null);
    }
  };

  const handleCreateTeam = () => {
    setCreateTeamPopup(true);
  };

  const handleCreateTeamClose = () => {
    setCreateTeamPopup(false);
    setIsEdit(false);
    setEditTeamData(null);
  };

  const handleEditTeam = (team) => {
    setOpenPopoverId(null);
    setEditTeamData(team);
    handleCreateTeam();
    setIsEdit(true);
  };

  const handleDeleteTeam = (team) => {
    setExitTeam(team);
    setDeleteTeam(true);
  };

  const handleDeleteConfirm = () => {
    setOpenPopoverId(null);
    setDeleteTeam(false);
    setIsLoader1(true);
    const teamId = exitTeam?.teamId;
    DeleteUserTeam((res) => {
      if (res?.status === 200) {
        setTeamData(allTeamData);
        localStorage.setItem("activeTeam", JSON.stringify(soloTeamData));
        setIsLoader1(false);
        getAllUserTeams();
        setDeleteConfirm(true);
        setIsTeamDetailsUpdateNeeded(true);
      } else {
        setDeleteConfirm(true);
        setIsLoader1(false);
        getAllUserTeams();
        setIsTeamDetailsUpdateNeeded(true);
      }
    }, teamId);
  };

  const handleDeleteConfirmClose = () => {
    setDeleteConfirm(false);
    setExitTeam(null);
  };

  const openLimitPop = () => {
    setIsTeamLimitPopup(true);
  };

  const handleViewPlans = () => {
    navigate("/contact");
    handleCloseLimit();
  };

  const handleCloseLimit = () => {
    setIsTeamLimitPopup(!isTeamLimitPoup);
  };

  const handleViewPlans1 = () => {
    navigate("/contact");
    handleCloseLimit1();
  };

  const handleCloseLimit1 = () => {
    setIsInviteLimitPopup(!isInviteLimitPoup);
  };

  const teamDeletetext = `${t("delete_team_text")} ${exitTeam?.teamName}?`;
  const confirmDeleteText = `${exitTeam?.teamName} ${t("delete_team_success")}`;

  return (
    <div className="workshop-teams">
      <div className="workshop-teams__container">
        <div className="workshop-teams__section">
          <div className="workshop-teams__section__teams">
            <div className="workshop-teams__section__teams__heading">
              <h2>{t("teams")}</h2>
            </div>
            <div className="workshop-teams__section__teams__details">
              <div className="workshop-teams__section__teams__details__list">
                <div className="workshop-teams__section__teams__details__list__main">
                  <div
                    className={`workshop-teams__section__teams__details__list__all ${
                      teamData?.teamId === -1 ? "active" : ""
                    }`}
                    onClick={() => handleChangeTeam(allTeamData)}
                  >
                    <img className="default-arrow" src={arrow} alt="arrow" />
                    <img className="active-arrow" src={arrowa} alt="arrow" />
                    <h4>{t("all_teams_member")}</h4>
                  </div>
                  <div className="workshop-teams__section__teams__details__list__search">
                    <input
                      placeholder={t("search_team_member")}
                      value={searchTeam}
                      onChange={(e) => {
                        const text = e.target.value;
                        const newText = text.replace(/\s+/g, " ");
                        setSearchTeam(newText);
                      }}
                    />
                    <img src={search} alt="search-icon" />
                  </div>
                  <div className="workshop-teams__section__teams__details__list__data">
                    <div
                      className={`workshop-teams__section__teams__details__list__data__team ${
                        teamData?.teamId === 0.1 ? "active" : ""
                      }`}
                      onClick={() => handleChangeTeam(soloTeamData)}
                    >
                      <div className="workshop-teams__section__teams__details__list__data__team__left">
                        <div className="workshop-teams__section__teams__details__list__data__team__icon">
                          <img
                            className="default-arrow"
                            src={arrow}
                            alt="arrow"
                          />
                          <img
                            className="active-arrow"
                            src={arrowa}
                            alt="arrow"
                          />
                        </div>
                        <div className="workshop-teams__section__teams__details__list__data__team__content">
                          <img
                            className="default-icon"
                            src={soloicon}
                            alt="arrow"
                          />
                          <img
                            className="active-icon"
                            src={soloicona}
                            alt="arrow"
                          />
                          <h4>{t("my_space")}</h4>
                        </div>
                      </div>
                    </div>
                    {filterTeamList?.length !== 0 ? (
                      filterTeamList?.map((team, index) => {
                        const active = teamData?.teamId === team?.teamId;
                        const openId = openPopoverId === team?.teamId;
                        return (
                          <div
                            className={`workshop-teams__section__teams__details__list__data__team ${
                              active ? "active" : ""
                            } ${openId ? "hovered" : ""}`}
                            key={index}
                            onClick={() => handleChangeTeam(team)}
                          >
                            <div className="workshop-teams__section__teams__details__list__data__team__left">
                              <div className="workshop-teams__section__teams__details__list__data__team__icon">
                                <img
                                  className="default-arrow"
                                  src={arrow}
                                  alt="arrow"
                                />
                                <img
                                  className="active-arrow"
                                  src={arrowa}
                                  alt="arrow"
                                />
                              </div>
                              <div className="workshop-teams__section__teams__details__list__data__team__content">
                                <img
                                  className="default-icon"
                                  src={teamicond}
                                  alt="team-icon"
                                />
                                <img
                                  className="active-icon"
                                  src={teamicona}
                                  alt="team-icon"
                                />
                                <h4>{team?.teamName}</h4>
                              </div>
                            </div>
                            <div
                              className="workshop-teams__section__teams__details__list__data__team__right"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <Popover
                                overlayClassName="custom-popover1"
                                content={
                                  <>
                                    <div
                                      className="workshop-teams__section__teams__details__list__data__team__right__sec"
                                      onClick={() => handleEditTeam(team)}
                                    >
                                      <img src={editicon} alt="view-icon" />
                                      <h5>{t("edit_name")}</h5>
                                    </div>
                                    <div
                                      className="workshop-teams__section__teams__details__list__data__team__right__sec"
                                      onClick={() => handleDeleteTeam(team)}
                                    >
                                      <img src={deleteicon} alt="view-icon" />
                                      <h5>{t("delete_teams")}</h5>
                                    </div>
                                  </>
                                }
                                arrow={false}
                                trigger="click"
                                placement="bottomRight"
                                open={openPopoverId === team?.teamId}
                                onOpenChange={(isOpen) =>
                                  handleOpenChange(team?.teamId, isOpen)
                                }
                              >
                                <img
                                  className="card-dots"
                                  src={dots}
                                  alt="dots-icon"
                                />
                              </Popover>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="workshop-teams__section__teams__details__list__data__loader">
                        {isShowLoader ? (
                          <img src={loader} alt="loader-gif" />
                        ) : searchTeam ? (
                          <p>
                            {t("no_teams_matching_search")}"{searchTeam}"
                          </p>
                        ) : (
                          <p>{t("no_teams_at_all")}</p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="workshop-teams__section__teams__details__list__button">
                  <button
                    onClick={
                      privilegeSummary?.memberMasterCount <= 1
                        ? openLimitPop
                        : handleCreateTeam
                    }
                  >
                    {t("create_team")}
                  </button>
                </div>
              </div>
              <div className="workshop-teams__section__teams__details__section">
                <div className="workshop-teams__section__teams__details__section__main">
                  <div className="workshop-teams__section__teams__details__section__tabs">
                    <div
                      className={`workshop-teams__section__teams__details__section__tab ${
                        activeTab === 1 ? "active" : ""
                      }`}
                      onClick={() => handleTab(1)}
                    >
                      <h4>{t("members")}</h4>
                    </div>
                    <div
                      className={`workshop-teams__section__teams__details__section__tab ${
                        activeTab === 2 ? "active" : ""
                      }`}
                      onClick={() => handleTab(2)}
                    >
                      <h4>{t("data")}</h4>
                    </div>
                  </div>
                  <div className="workshop-teams__section__teams__details__section__content">
                    {activeTab === 1 ? (
                      <TeamMemberDetails
                        team={teamData}
                        onOpen={handleExistingMember}
                        onOpen1={handleNewMember}
                        privilegeSummary={privilegeSummary}
                        getPrivilegeSummary={getPrivilegeSummary}
                        isInvited={isInvited}
                        teamDetailsUpdateNeeded={teamDetailsUpdateNeeded}
                        setIsTeamDetailsUpdateNeeded={
                          setIsTeamDetailsUpdateNeeded
                        }
                      />
                    ) : (
                      <TeamdataDetails team={teamData} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showExistingTeamMembers && (
          <AddExistingMember
            team={teamData}
            onClose={handleExistingMember}
            handleCreateTeam={handleCreateTeam}
            teamCreated={teamCreated}
            setTeamCreated={setTeamCreated}
            setIsEdit={setIsEdit}
            setEditTeamData={setEditTeamData}
            handleNewMember={handleNewMember}
            setIsInvited={setIsInvited}
            isInvited={isInvited}
            setIsInviteLimitPopup={setIsInviteLimitPopup}
          />
        )}
        {showInviteTeamMembers && (
          <AddNewMember
            team={teamData}
            onClose={handleNewMember}
            teamCreated={teamCreated}
            setTeamCreated={setTeamCreated}
            handleExistingMember={handleExistingMember}
            privilegeSummary={privilegeSummary}
            getPrivilegeSummary={getPrivilegeSummary}
            setIsInvited={setIsInvited}
            isInvited={isInvited}
            setShowInviteTeamMembers={setShowInviteTeamMembers}
            setIsInviteLimitPopup={setIsInviteLimitPopup}
          />
        )}
        {createTeamPopup && (
          <CreateOrEditTeam
            team={editTeamData}
            onClose={handleCreateTeamClose}
            edit={isEdit}
            setIsLoader1={setIsLoader1}
            teamCreated={teamCreated}
            setTeamCreated={setTeamCreated}
            handleChangeTeam={handleChangeTeam}
            handleExistingMember={handleExistingMember}
            setTeamData={setTeamData}
            setIsTeamLimitPopup={setIsTeamLimitPopup}
          />
        )}
        {deleteTeam && (
          <Confirm
            buttonText={t("confirm")}
            isCancelRequired={true}
            confirmTitle={`${t("delete_team")} ${exitTeam?.teamName}`}
            content={teamDeletetext}
            onConfirm={handleDeleteConfirm}
            onCancel={() => setDeleteTeam(false)}
            cancelButtonText={t("cancel")}
          />
        )}
        {deleteConfirm && (
          <Confirm
            buttonText={t("ok")}
            isCancelRequired={false}
            confirmTitle={t("congratulations")}
            content={confirmDeleteText}
            onConfirm={handleDeleteConfirmClose}
            onCancel={handleDeleteConfirmClose}
          />
        )}
        {isTeamLimitPoup && (
          <Confirm
            isCancelRequired={true}
            confirmTitle={t("team_limit_heading")}
            content={t("team_limit_content")}
            onConfirm={handleViewPlans}
            onCancel={handleCloseLimit}
            cancelButtonText={t("ok")}
            buttonText={t("contact_airi")}
          />
        )}
        {isInviteLimitPoup && (
          <Confirm
            isCancelRequired={true}
            confirmTitle={t("Members limit")}
            content={t("member_limit_text")}
            onConfirm={handleViewPlans1}
            onCancel={handleCloseLimit1}
            cancelButtonText={t("ok")}
            buttonText={t("contact_airi")}
          />
        )}
        {isLoader1 && <Loader />}
      </div>
    </div>
  );
};

export default WorkshopTeam;
