import React from "react";

const ContactIcon = ({ fill = "#263238", size = "22" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7474 2H5.08073C3.97607 2.00132 2.91703 2.44073 2.13591 3.22185C1.3548 4.00296 0.915386 5.062 0.914063 6.16667L0.914062 16.1667C0.915386 17.2713 1.3548 18.3304 2.13591 19.1115C2.91703 19.8926 3.97607 20.332 5.08073 20.3333H16.7474C17.8521 20.332 18.9111 19.8926 19.6922 19.1115C20.4733 18.3304 20.9127 17.2713 20.9141 16.1667V6.16667C20.9127 5.062 20.4733 4.00296 19.6922 3.22185C18.9111 2.44073 17.8521 2.00132 16.7474 2ZM5.08073 3.66667H16.7474C17.2464 3.66765 17.7337 3.81793 18.1465 4.09817C18.5594 4.37841 18.8789 4.77579 19.0641 5.23917L12.6824 11.6217C12.2128 12.0894 11.5769 12.3521 10.9141 12.3521C10.2512 12.3521 9.61537 12.0894 9.14573 11.6217L2.76406 5.23917C2.94919 4.77579 3.26874 4.37841 3.6816 4.09817C4.09446 3.81793 4.58174 3.66765 5.08073 3.66667ZM16.7474 18.6667H5.08073C4.41769 18.6667 3.7818 18.4033 3.31296 17.9344C2.84412 17.4656 2.58073 16.8297 2.58073 16.1667V7.41667L7.9674 12.8C8.74959 13.5802 9.80928 14.0184 10.9141 14.0184C12.0188 14.0184 13.0785 13.5802 13.8607 12.8L19.2474 7.41667V16.1667C19.2474 16.8297 18.984 17.4656 18.5152 17.9344C18.0463 18.4033 17.4104 18.6667 16.7474 18.6667Z"
        fill={fill}
      />
    </svg>
  );
};

export default ContactIcon;
