import React, { createContext, useState } from "react";

const RenderToolsetContext = createContext();

function RenderToolsetProvider({ children }) {
  const [renderToolsetData, setRenderToolsetData] = useState(null);

  const updateRenderToolsetData = (newData) => {
    setRenderToolsetData((prevData) => ({ ...prevData, ...newData }));
  };

  const clearRenderToolsetData = () => {
    setRenderToolsetData(null);
  };

  return (
    <RenderToolsetContext.Provider
      value={{
        renderToolsetData,
        updateRenderToolsetData,
        clearRenderToolsetData,
      }}
    >
      {children}
    </RenderToolsetContext.Provider>
  );
}

export { RenderToolsetContext, RenderToolsetProvider };
