/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import "./GalleryHeader.scss";
import allicon from "../../assets/images/workspace/icons/allb.png";
import upscaledicon from "../../assets/images/workspace/icons/upscaledb.png";
import favorite from "../../assets/images/workspace/icons/favorite.png";
import selecticon from "../../assets/images/icons/select.png";
import downloadicon from "../../assets/images/icons/download.png";
import deleteicon from "../../assets/images/icons/delete.png";
import archi from "../../assets/images/icons/archi.png";
import inte from "../../assets/images/icons/inte.png";
import land from "../../assets/images/icons/land.png";
import draw from "../../assets/images/icons/draw.png";
import filter from "../../assets/images/icons/filter.png";
import filter1 from "../../assets/images/icons/filterb.png";
import { t } from "i18next";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { ResponsiveDataContext } from "../../store/ResponsiveData";

const CustomOption = (props) => {
  const { responsiveData } = useContext(ResponsiveDataContext);
  return (
    <components.Option {...props}>
      {props.data.icon && (
        <img
          src={props.data.icon}
          alt="option-icon"
          style={{
            marginRight: 10,
            width: `${responsiveData ? "12px" : "18px"}`,
            height: `${responsiveData ? "12px" : "18px"}`,
          }}
        />
        )}
      <span style={{ fontSize: `${responsiveData ? "12px" : "16px"}` }}>
        {props.data.label}
      </span>
    </components.Option>
  );
};

const GalleryHeader = ({
  isSelectMode,
  setIsSelectMode,
  selectedImages,
  setSelectedImages,
  downloadIcon,
  setDownloadIcon,
  deleteIcon,
  setDeleteIcon,
  setAlertText,
  setShowConfirmModal,
  searchOptions,
  setSearchOptions,
  activeTab,
  setActiveTab,
  isShowIcons,
  isLoading,
  setAlertContent,
  isFavoriteTab,
  isFavoriteImages
}) => {
  const { responsiveData } = useContext(ResponsiveDataContext);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const options = [
    { value: t("architecture_text"), label: t("architecture"), icon: archi },
    { value: t("interior_text"), label: t("interior"), icon: inte },
    { value: t("lanscape_text"), label: t("landscape_name"), icon: land },
    { value: t("drawing_text"), label: t("drawing"), icon: draw },
  ];

  const customStyles = {
    control: (base, state) => ({
      ...base,
      width: "280px",
      borderRadius: "8px",
      border: state.isFocused ? "1px solid #263238" : "#F8F8F9",
      boxShadow: state.isFocused ? "0 0 0 1px #263238" : "none",
      "&:hover": {
        border: state.isFocused ? "1px solid #263238" : "none",
      },
    }),
    menu: (base) => ({
      ...base,
      width: "100%",
      borderColor: "#263238",
      backgroundColor: "#f8f8f9",
      borderRadius: "8px",
    }),
    option: (base) => ({
      ...base,
      padding: `${responsiveData ? "4px 12px" : "8px 12px"}`,
      color: "#A8ADAF",
      borderRadius: "8px",
      backgroundColor: "#f8f8f9",
      ":hover": {
        backgroundColor: "white",
        color: "#677074",
        borderRadius: "8px",
      },
      ":active": {
        ...base[":active"],
        backgroundColor: "white",
        color: "#000000",
      },
    }),
  };

  useEffect(() => {
    if (isSelectMode === false) {
      setSelectedImages([]);
    }
  }, [isSelectMode]);

  const handleSelectIconClick = () => {
    setIsSelectMode(!isSelectMode);
  };

  const handleDownloadPopUp = () => {
    if (selectedImages?.length !== 0) {
      setDownloadIcon(true);
    } else {
      setAlertText(t("not_selected_any_image"));
      setAlertContent(t("not_selected_any_image_content"));
      setShowConfirmModal(true);
    }
  };

  const handleDeletePopUp = () => {
    if (selectedImages?.length !== 0) {
      if (selectedImages.some(id => isFavoriteImages[id])) {
        alert(t("cannot_delete_favorite"));
      }else{
        setDeleteIcon(true);
      }
    } else {
      setAlertText(t("not_selected_any_image"));
      setAlertContent(t("not_selected_any_image_content"));
      setShowConfirmModal(true);
    }
  };

  const handleChange = (selected) => {
    const values = selected.map((option) => option.value);
    const valueString = values.join(",");
    const valueObject = { valueString };

    setSelectedOptions(selected);
    setSearchOptions(valueObject);
  };

  return (
    <div className="gallery-header">
      <div className="gallery-header__container">
        <div className="gallery-header__container__left">
          <button
            className={`gallery-header__container__left__sec ${
              activeTab === "all" && "active"
            }`}
            onClick={() => setActiveTab("all")}
            disabled={isLoading}
          >
            <img src={allicon} alt="all-icon" />
            <h4>{t("all")}</h4>
          </button>
          <button
            className={`gallery-header__container__left__sec ${
              activeTab === "upscale" && "active"
            }`}
            onClick={() => setActiveTab("upscale")}
            disabled={isLoading}
          >
            <img src={upscaledicon} alt="upscaled-icon" />
            <h4>{t("upscale_text")}</h4>
          </button>
          {isFavoriteTab && (
            <button
              className={`gallery-header__container__left__sec ${
                activeTab === "favorite" && "active"
              }`}
              onClick={() => setActiveTab("favorite")}
              disabled={isLoading}
            >
              <img src={favorite} alt="upscaled-icon" />
              <h4>{t("favorite")}</h4>
            </button>
          )}
        </div>
        <div className="gallery-header__container__right">
          {isShowIcons && (
            <>
              <div
                className={`gallery-header__container__right__sec ${
                  isSelectMode === true ? "select" : ""
                }`}
              >
                <img
                  src={selecticon}
                  alt="select-icon"
                  onClick={handleSelectIconClick}
                />
              </div>
              <div
                className={`gallery-header__container__right__sec ${
                  downloadIcon === true ? "download" : ""
                }`}
              >
                <img
                  src={downloadicon}
                  alt="download-icon"
                  onClick={handleDownloadPopUp}
                />
              </div>
              <div
                className={`gallery-header__container__right__sec ${
                  deleteIcon === true ? "delete" : ""
                }`}
              >
                <img
                  src={deleteicon}
                  alt="delete-icon"
                  onClick={handleDeletePopUp}
                />
              </div>
            </>
          )}
          <div className="gallery-header__container__right__sec search">
            <CreatableSelect
              options={options}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{ DropdownIndicator:null, Option: CustomOption }}
              value={selectedOptions}
              onChange={handleChange}
              classNamePrefix="react-select"
              styles={customStyles}
              placeholder="Search"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GalleryHeader;
