/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { createContext, useState, useEffect } from "react";
import { AIRI_BASE_URL_WS } from "../utils/api-constant";

const UserSocketContext = createContext();

function UserSocketProvider({ children }) {
  const token = localStorage.getItem("token");
  const localVersion = localStorage.getItem("currentVersion");
  const [socketData, setSocketData] = useState({
    isSocketApiCall: false,
    isShowNotification: false,
    isNewVersionPopup: false,
    isNewVersionExit: true,
    isExitPopup: false,
    isSocketClose: false,
  });
  const [isWebSocket, setIsWebSocket] = useState(null);
  const [isNotification, setIsNotification] = useState();
  const [latestVersion, setLatestVersion] = useState();
  const [isTeamInvite, setIsTeamInvite] = useState({
    showRedDot: false,
    showTeamNotification: false,
    engHeading: "",
    engContent: "",
    chHeading: "",
    chContent: "",
  });
  const [openTeamNotification, setOpenTeamNotification] = useState(false);
  const [teamMemberNotification, setTeamMemberNotification] = useState(false);
  const [teamMemberNotificationData, setTeamMemberNotificationData] =
    useState(null);
  const [subscriptionLimit, setSubscriptionLimit] = useState(false);
  const [isShowSubscription, setIsShowSubscription] = useState(false);

  let reconnectDelay = 5000;
  let maxRetries = 100;
  let retryCount = 0;
  let isReconnecting = false;
  let isSocketClosed = false;

  const createSocketConnection = () => {
    if (
      token !== null &&
      token !== undefined &&
      !isReconnecting &&
      !isSocketClosed
    ) {
      var socket = new WebSocket(`wss://${AIRI_BASE_URL_WS}/socketUser`, [
        "Bearer",
        token,
      ]);

      socket.onopen = () => {
        console.log("open");
      };

      socket.onmessage = (event) => {
        const message = event.data;
        getMessage(message);
      };

      socket.onerror = (error) => {
        console.error("WebSocket encountered an error:", error);
      };

      socket.onclose = (event, index) => {
        console.log("WebSocket closed. Attempting to reconnect...");
        socket = null;
        retryCount++;
        isSocketClosed = true;

        if (!isReconnecting) {
          retryCount++;

          if (retryCount <= maxRetries) {
            setTimeout(() => {
              createSocketConnection();
            }, reconnectDelay);
            reconnectDelay *= 2;
          } else {
            console.error("Maximum reconnection attempts reached.");
          }
        }
      };

      setIsWebSocket(socket);
    }
  };

  const openUserSocket = () => {
    if (!isWebSocket || isWebSocket.readyState === WebSocket.CLOSED) {
      var socket = new WebSocket(`wss://${AIRI_BASE_URL_WS}/socketUser`, [
        "Bearer",
        token,
      ]);

      socket.onopen = () => {
        console.log("open");
      };

      socket.onmessage = (event) => {
        const message = event.data;
        getMessage(message);
      };

      socket.onerror = (error) => {
        console.error("WebSocket encountered an error:", error);
      };

      socket.onclose = (event, index) => {
        console.log("WebSocket closed. Attempting to reconnect...");
        socket = null;
        retryCount++;
        isSocketClosed = true;

        if (!isReconnecting) {
          retryCount++;

          if (retryCount <= maxRetries) {
            setTimeout(() => {
              createSocketConnection();
            }, reconnectDelay);
            reconnectDelay *= 2;
          } else {
            console.error("Maximum reconnection attempts reached.");
          }
        }
      };

      setIsWebSocket(socket);
    }
  };

  useEffect(() => {
    if (token) {
      createSocketConnection();
    }
  }, []);

  useEffect(() => {
    if (token) {
      if (socketData?.isSocketApiCall === true) {
        createSocketConnection();

        updateSocketData({
          isSocketApiCall: false,
        });
      }
    }
  }, [token, socketData?.isSocketApiCall]);

  const getMessage = (message) => {
    const parts = message?.split(";");
    const notification = parts[0]?.trim() || null;
    const engHeading = parts[1]?.trim() || null;
    const engContent = parts[2]?.trim() || null;
    const chHeading = parts[3]?.trim() || null;
    const chContent = parts[4]?.trim() || null;
    let showNotification = false;
    let showTeamNotification = false;
    let showRedDot = false;

    if (notification === "notification") {
      showNotification = true;
      const commaSeparatedValues = {
        showNotification,
        engHeading,
        engContent,
        chHeading,
        chContent,
      };
      setIsNotification(commaSeparatedValues);
    }
    if (typeof message === "string" && message.startsWith("{")) {
      const receivedMessage = JSON.parse(message);
      if (localVersion !== undefined && localVersion !== null && localVersion !== "" && localVersion !== "null") {
        if (
          receivedMessage?.type === "BROADCAST" &&
          localVersion !== receivedMessage?.version
        ) {
          if (token) {
            if (receivedMessage?.version !== "0") {
              if (receivedMessage?.version !== "-1") {
                localStorage.setItem(
                  "currentVersion",
                  receivedMessage?.version
                );
              } else {
                updateSocketData({
                  isNewVersionExit: false,
                });
              }
            }
            updateSocketData({
              isNewVersionPopup: true,
            });
            setLatestVersion(receivedMessage);
          }
        } else {
          updateSocketData({
            isNewVersionPopup: false,
          });
        }
      } else {
        localStorage.setItem("currentVersion", receivedMessage?.version);
      }
    }
    if (typeof message === "string" && message.startsWith("closing")) {
      const splitData = message?.split("|");
      const loginMessage = splitData[0]?.trim() || null;
      const loginToken = splitData[1]?.trim() || null;
      if (
        loginMessage === "closing" &&
        loginToken !== localStorage.getItem("token")
      ) {
        updateSocketData({
          isExitPopup: true,
        });
      }
    }
    if (notification === "team-invite") {
      setOpenTeamNotification(false);
      showTeamNotification = true;
      showRedDot = true;
      const commaSeparatedValues = {
        showRedDot,
        showTeamNotification,
        engHeading,
        engContent,
        chHeading,
        chContent,
      };
      setIsTeamInvite(commaSeparatedValues);
    }
    if (notification === "team-status") {
      setTeamMemberNotification(true);
      const data = {
        en: engContent,
        ch: chContent,
      };
      setTeamMemberNotificationData(data);
    }
    if (typeof message === "string" && message.startsWith("expired")) {
      const splitData = message?.split(";");
      const loginMessage = splitData[0]?.trim() || null;
      if (loginMessage === "expired") {
        updateSocketData({
          isTokenExpirePopup: true,
        });
      }
    }
  };

  useEffect(() => {
    if (isNotification?.showNotification === true) {
      updateSocketData({
        isShowNotification: true,
      });
    } else {
      updateSocketData({
        isShowNotification: false,
      });
    }
  }, [isNotification?.showNotification]);

  const updateSocketData = (newData) => {
    setSocketData((prevData) => ({ ...prevData, ...newData }));
  };

  const handleTeamNofications = () => {
    setOpenTeamNotification(!openTeamNotification);
  };

  const handleTeamMemberNofications = () => {
    setTeamMemberNotification(false);
  };

  const handleShowSubscription = () => {
    setIsShowSubscription(!isShowSubscription);
    updateSocketData({
      isShowNotification: false,
    });
  };

  return (
    <UserSocketContext.Provider
      value={{
        socketData,
        isNotification,
        latestVersion,
        updateSocketData,
        isTeamInvite,
        openTeamNotification,
        handleTeamNofications,
        setIsTeamInvite,
        teamMemberNotification,
        handleTeamMemberNofications,
        subscriptionLimit,
        setSubscriptionLimit,
        openUserSocket,
        isShowSubscription,
        setIsShowSubscription,
        handleShowSubscription,
        teamMemberNotificationData,
      }}
    >
      {children}
    </UserSocketContext.Provider>
  );
}

export { UserSocketContext, UserSocketProvider };
