import React, { useContext, useEffect, useRef, useState } from "react";
import "./TeamMemberDetails.scss";
import addIcon from "../../../assets/images/teams/icons/add_member.png";
import addIcon1 from "../../../assets/images/teams/icons/add_new_member.png";
import addIcona from "../../../assets/images/teams/icons/add_member_a.png";
import addIcon1a from "../../../assets/images/teams/icons/add_new_member_a.png";
import loader from "../../../assets/images/workspace/upscale-gif.gif";
import reinvite from "../../../assets/images/teams/icons/reinvite.png";
import deleteicon from "../../../assets/images/icons/delete.png";
import { useTranslation } from "react-i18next";
import {
  GetMembersDataOfTeam,
  InviteExistingUser,
  RemoveUserFromAllTeams,
  RemoveUserFromTeam,
  formatTeamNames,
} from "../../../utils/apiCalls";
import { TeamDesignContext } from "../../../store/TeamDesignData";
import { UserSocketContext } from "../../../store/UserSocketData";
import { Popover } from "antd";
import Confirm from "../../confirmModal/confirm";
import Loader from "../../loader";

const TeamMemberDetails = ({
  team,
  onOpen,
  onOpen1,
  privilegeSummary,
  getPrivilegeSummary,
  isInvited,
  teamDetailsUpdateNeeded,
  setIsTeamDetailsUpdateNeeded,
}) => {
  const { t } = useTranslation();
  const teamApiRef = useRef(false);
  const { getAllUserTeams } = useContext(TeamDesignContext);
  const { updateSocketData, teamMemberNotification } =
    useContext(UserSocketContext);
  const [teamDetails, setTeamDetails] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [isLoader1, setIsLoader1] = useState(false);
  const [removeTeam, setRemoveTeam] = useState(false);
  const [removeConfirm, setRemoveConfirm] = useState(false);
  const [inviteSucess, setInviteSucess] = useState(false);
  const [userRemove, setUserRemove] = useState(null);

  useEffect(() => {
    teamApiRef.current = false;
    if (
      (!teamApiRef.current && !teamMemberNotification) ||
      teamDetailsUpdateNeeded
    ) {
      teamApiRef.current = true;
      getTeamDetails();
      getAllUserTeams();
      getPrivilegeSummary();
      setIsTeamDetailsUpdateNeeded(false);
    }
  }, [team, isInvited, teamMemberNotification, teamDetailsUpdateNeeded]);

  const getTeamDetails = () => {
    setIsLoader(true);
    const payload = {
      teamId:
        team?.teamId !== 0.1
          ? team?.teamId === -1
            ? "null"
            : team?.teamId
          : 0,
    };
    setTeamDetails([]);
    GetMembersDataOfTeam((res) => {
      if (res?.status === 200) {
        setIsLoader(false);
        setTeamDetails(res?.data);
      } else {
        setTeamDetails([]);
        setIsLoader(false);
      }
    }, payload);
  };

  function formatDate(isoString) {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}/${month}/${day}`;
  }

  const handleRemove = (team) => {
    setUserRemove(team);
    setRemoveTeam(true);
  };

  const handleRemoveUser = () => {
    setTeamDetails([]);
    setRemoveTeam(false);
    setIsLoader1(true);
    const payload = {
      teamId: team?.teamId,
      userId: userRemove?.memberUserId,
    };
    RemoveUserFromTeam((res) => {
      if (res?.status === 200) {
        setIsLoader1(false);
        setRemoveConfirm(true);
        getTeamDetails();
        getAllUserTeams();
        getPrivilegeSummary();
      } else if (res?.message === "Invalid access token") {
        updateSocketData({
          isExitPopup: true,
        });
      } else {
        setRemoveConfirm(true);
        getAllUserTeams();
      }
    }, payload);
  };

  const handleInvite = (id) => {
    setIsLoader1(true);
    const payload = {
      teamId: team?.teamId,
      userId: [id],
    };
    InviteExistingUser((res) => {
      if (res?.status === 200) {
        setIsLoader1(false);
        setInviteSucess(true);
        getAllUserTeams();
        getTeamDetails();
        getPrivilegeSummary();
      } else if (res?.status === 203) {
        onOpen1();
        setIsLoader1(false);
      } else if (res?.message === "Invalid access token") {
        updateSocketData({
          isExitPopup: true,
        });
      } else {
        setInviteSucess(true);
      }
    }, payload);
  };

  const handleRemoveFromAllTeams = () => {
    setTeamDetails([]);
    setRemoveTeam(false);
    setIsLoader1(true);
    const payload = {
      userId: userRemove?.memberUserId,
    };
    RemoveUserFromAllTeams((res) => {
      if (res?.status === 200) {
        setIsLoader1(false);
        setRemoveConfirm(true);
        getTeamDetails();
        getAllUserTeams();
        getPrivilegeSummary();
      } else if (res?.message === "Invalid access token") {
        updateSocketData({
          isExitPopup: true,
        });
        setIsLoader1(false);
      } else {
        getTeamDetails();
        setIsLoader1(false);
        getAllUserTeams();
        setRemoveConfirm(true);
      }
    }, payload);
  };

  const handleRemoveConfirmClose = () => {
    setRemoveConfirm(false);
  };

  const teamCount =
    teamDetails?.length !== 0
      ? teamDetails?.filter(
          (team) =>
            team?.memberRoleId === 1 ||
            team?.memberRoleId === 2 ||
            team?.memberRoleId === 4
        )
      : [];

  const teamExitText = `${t("remove_team_text")} ${userRemove?.username} ${t(
    "remove_team_text1"
  )}`;
  const confirmExitText = ` ${userRemove?.username} ${t(
    "remove_team_success"
  )}`;
  const teamExitText1 = `${t("remove_team_text")} ${userRemove?.username} ${t(
    "remove_team_text2"
  )}`;
  const confirmExitText1 = ` ${userRemove?.username} ${t(
    "remove_team_success1"
  )}`;

  return (
    <div className="team-member-details">
      <div className="team-member-details__container">
        {team?.teamId === 0.1 ? (
          <div className="team-member-details__heading">
            <h2>{t("my_space_member")}</h2>
          </div>
        ) : (
          <>
            {team?.teamId === -1 ? (
              <div className="team-member-details__heading">
                <div className="team-member-details__heading__name">
                  <h2>{t("members")}</h2>
                </div>
                <div className="team-member-details__heading__content">
                  <h5
                    style={{
                      color:
                        privilegeSummary?.userMemberLeftCount <= 0
                          ? "red"
                          : "#2ba471",
                    }}
                  >
                    {t("members_limit")}{" "}
                    {Math.max(
                      (privilegeSummary?.memberMasterCount || 0) -
                        (privilegeSummary?.userMemberLeftCount || 0),
                      1
                    )}
                    {""}/{Math.max(privilegeSummary?.memberMasterCount || 0, 1)}
                  </h5>
                </div>
              </div>
            ) : (
              <div className="team-member-details__heading">
                <div className="team-member-details__heading__name">
                  <h2>
                    {formatTeamNames(team?.teamName)} &middot;{" "}
                    {`${teamCount?.length}`} {t("people")}
                  </h2>
                </div>
                <div className="team-member-details__heading__buttons">
                  <button onClick={onOpen}>
                    <img
                      className="default-icon"
                      src={addIcon}
                      alt="add-icon"
                    />
                    <img
                      className="active-icon"
                      src={addIcona}
                      alt="add-icon"
                    />
                    <span>{t("add_member")}</span>
                  </button>
                  <button onClick={onOpen1}>
                    <img
                      className="default-icon"
                      src={addIcon1}
                      alt="add-icon"
                    />
                    <img
                      className="active-icon"
                      src={addIcon1a}
                      alt="add-icon"
                    />
                    <span>{t("invite_member")}</span>
                  </button>
                </div>
              </div>
            )}
          </>
        )}
        <div className="team-member-details__section">
          <div className="team-member-details__section__heading">
            <div className="team-member-details__section__heading__sec">
              <h5>{t("member")}</h5>
            </div>
            <div className="team-member-details__section__heading__sec">
              <h5>{t("last_active")}</h5>
            </div>
            <div className="team-member-details__section__heading__sec">
              {team?.teamId === -1 ? (
                <h5>{t("teams_joined")}</h5>
              ) : (
                <h5>{t("join_time")}</h5>
              )}
            </div>
            {team?.teamId !== -1 ? (
              <div className="team-member-details__section__heading__sec">
                <h5>{t("status")}</h5>
              </div>
            ) : (
              <div className="team-member-details__section__heading__sec">
                <h5>{}</h5>
              </div>
            )}
          </div>
          {teamDetails?.length !== 0 ? (
            <div className="team-member-details__section__list">
              {teamDetails?.map((isTeam, index) => {
                const dateString =
                  isTeam?.lastCreatedOn !== ""
                    ? isTeam?.lastCreatedOn
                    : isTeam?.joinedTime;
                const date = new Date(dateString);
                const now = new Date();

                const timeDifference = now - date;
                const millisecondsInMinute = 60 * 1000;
                const millisecondsInHour = 60 * millisecondsInMinute;
                const millisecondsInDay = 24 * millisecondsInHour;
                const millisecondsInWeek = 7 * millisecondsInDay;

                let formattedDate;

                if (timeDifference < millisecondsInMinute) {
                  const secondsAgo = Math.floor(timeDifference / 1000);
                  formattedDate = `${secondsAgo} seconds ago`;
                } else if (timeDifference < millisecondsInHour) {
                  const minutesAgo = Math.floor(
                    timeDifference / millisecondsInMinute
                  );
                  formattedDate =
                    minutesAgo === 1
                      ? `${minutesAgo} minute ago`
                      : `${minutesAgo} minutes ago`;
                } else if (timeDifference < millisecondsInDay) {
                  const hoursAgo = Math.floor(
                    timeDifference / millisecondsInHour
                  );
                  formattedDate =
                    hoursAgo === 1
                      ? `${hoursAgo} hour ago`
                      : `${hoursAgo} hours ago`;
                } else if (timeDifference < millisecondsInWeek) {
                  const daysAgo = Math.floor(
                    timeDifference / millisecondsInDay
                  );
                  formattedDate =
                    daysAgo === 1
                      ? `${daysAgo} day ago`
                      : `${daysAgo} days ago`;
                } else {
                  const options = { day: "numeric", month: "short" };
                  formattedDate = date.toLocaleString("en-US", options);
                }
                const formattedjoinDate = formatDate(
                  isTeam?.joinedTime || isTeam?.lastCreatedOn
                );
                return (
                  <div
                    className="team-member-details__section__list__sec"
                    key={index}
                  >
                    <div className="team-member-details__section__list__sec__data">
                      <div className="team-member-details__section__list__sec__data__icon">
                        <img src={isTeam?.avatarUrl} alt="avatar" />
                      </div>
                      <div className="team-member-details__section__list__sec__data__content">
                        <h4 className="data-max-length-Username">{formatTeamNames(isTeam?.username,13)} </h4>
                        <h4>{isTeam?.phoneNumber}</h4>
                      </div>
                    </div>
                    <div className="team-member-details__section__list__sec__data">
                      <h4>{formattedDate}</h4>
                    </div>
                    <div
                      className={`team-member-details__section__list__sec__data ${
                        team?.teamId === -1 ? "data2" : ""
                      }`}
                    >
                      {team?.teamId === -1 ? (
                        <h4>{formatTeamNames(isTeam?.teams)}</h4>
                      ) : (
                        <h4>{formattedjoinDate}</h4>
                      )}
                    </div>
                    {team?.teamId !== -1 ? (
                      <div className="team-member-details__section__list__sec__data">
                        <h4 style={{ color: isTeam?.color }}>
                          {t(`team_${isTeam?.memberRoleName}`)}
                        </h4>
                        <div className="status-icon">
                          {team?.roleId === 1 &&
                            isTeam?.memberRoleId !== 1 &&
                            isTeam?.memberRoleId !== 2 &&
                            isTeam?.memberRoleId !== 4 && (
                              <button
                                className="button-invite"
                                onClick={() =>
                                  handleInvite(isTeam?.memberUserId)
                                }
                              >
                                <Popover
                                  overlayClassName="custom-popover"
                                  placement="top"
                                  content={t("re_invite")}
                                >
                                  <img src={reinvite} alt="reinvite-icon" />
                                </Popover>
                              </button>
                            )}
                        </div>
                        <div className="status-icon">
                          {team?.roleId === 1 &&
                            isTeam?.memberRoleId !== 1 &&
                            isTeam?.memberRoleId !== 5 &&
                            isTeam?.memberRoleId !== 6 &&
                            isTeam?.memberRoleId !== 7 && (
                              <button
                                className="button-delete"
                                onClick={() => handleRemove(isTeam)}
                              >
                                <Popover
                                  overlayClassName="custom-popover"
                                  placement="top"
                                  content={t("delete")}
                                >
                                  <img
                                    src={deleteicon}
                                    className="delete"
                                    alt="delete-icon"
                                  />
                                </Popover>
                              </button>
                            )}
                        </div>
                      </div>
                    ) : (
                      <div className="team-member-details__section__list__sec__data data1">
                        <div className="team-member-details__section__list__sec__data__sec">
                          <div className="status-icon">
                            {isTeam?.memberRoleId !== 1 && (
                              <button
                                className="button-delete"
                                onClick={() => handleRemove(isTeam)}
                              >
                                <Popover
                                  placement="top"
                                  content={t("delete")}
                                  overlayClassName="custom-popover"
                                >
                                  <img
                                    src={deleteicon}
                                    className="delete"
                                    alt="delete-icon"
                                  />
                                </Popover>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="team-member-details__section__loader">
              {isLoader ? (
                <img src={loader} alt="loader-gif" />
              ) : (
                <p>{t("No Team Data Found")}</p>
              )}
            </div>
          )}
        </div>
        {removeTeam && (
          <Confirm
            buttonText={t("confirm")}
            isCancelRequired={true}
            confirmTitle={t("remove_team")}
            content={team?.teamId === -1 ? teamExitText1 : teamExitText}
            onConfirm={
              team?.teamId === -1 ? handleRemoveFromAllTeams : handleRemoveUser
            }
            onCancel={() => setRemoveTeam(false)}
            cancelButtonText={t("cancel")}
          />
        )}
        {removeConfirm && (
          <Confirm
            buttonText={t("ok")}
            isCancelRequired={false}
            confirmTitle={t("congratulations")}
            content={team?.teamId === -1 ? confirmExitText1 : confirmExitText}
            onConfirm={handleRemoveConfirmClose}
            onCancel={handleRemoveConfirmClose}
          />
        )}
        {inviteSucess && (
          <Confirm
            buttonText={t("ok")}
            isCancelRequired={false}
            confirmTitle={t("team_invite_success")}
            content={t("team_invite_success_text")}
            onConfirm={() => setInviteSucess(false)}
            onCancel={() => setInviteSucess(false)}
          />
        )}
        {isLoader1 && <Loader />}
      </div>
    </div>
  );
};

export default TeamMemberDetails;
