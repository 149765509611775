import React from "react";
import "./TooltipTopChecks.scss";

const TooltipTopChecks = ({ TooltipContent, display }) => {
  const contentLines = TooltipContent?.split("/n");
  return (
    <div className="tooltipchecks">
      <div className="tooltipchecks__section">
        <div className="tooltipchecks__arrow"></div>
      </div>
      <div className="tooltipchecks__container">
        {contentLines?.map((item, index) => (
          <p key={index}>{item?.trim()}</p>
        ))}
      </div>
    </div>
  );
};

export default TooltipTopChecks;
