/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import "./CommunityGallery.scss";
import SideNavComponent from "../../components/SideNav/SideNavComponent";
import downarrow from "../../assets/images/chatgpt/down-arrow.png";
import gif from "../../assets/images/workspace/upscale-gif.gif";
import fav from "../../assets/images/workspace/icons/feed-fav.png";
import fav1 from "../../assets/images/workspace/icons/feed-fav1.png";
import fav2 from "../../assets/images/workspace/icons/feed-fav2.png";
import { UserContext } from "../../store/UserDetailsStore";
import { useTranslation } from "react-i18next";
import { GetAllImagesCommunityGalleryMongo } from "../../utils/apiCalls";
import PLanEnd from "../../components/plan-end/PLanEnd";
import WorkSpaceFeedPopUp from "../../components/WorkspaceComponents/workspacefeedpopup/WorkSpaceFeedPopUp";
import GalleryHeader from "../../components/GalleryHeader/GalleryHeader";
import { UserSocketContext } from "../../store/UserSocketData";
import { TeamDesignContext } from "../../store/TeamDesignData";
import UserPortalLayout from "../../components/UserPortalLayout/UserPortalLayout";
import FavoriteTooltip from "../../components/Tooltips/FavotiteTooltip";
import { ResponsiveDataContext } from "../../store/ResponsiveData";

const CommunityGallery = () => {
  const { updateSocketData, handleShowSubscription } =
    useContext(UserSocketContext);
  const { isTeamChanges } = useContext(TeamDesignContext);
  const { responsiveData } = useContext(ResponsiveDataContext);
  const { t } = useTranslation();
  const galleryApiRef = useRef(false);
  const { isPrivilagesDetails } = useContext(UserContext);
  const [isPageIndex, setIsPageIndex] = useState(0);
  const [isPageSize, setIsPageSize] = useState(40);
  const [isGalleryImages, setIsGalleryImages] = useState([]);
  const [isGalleryNewImages, setIsGalleryNewImages] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState([]);
  const [isCurrentIndex, setIsCurrentIndex] = useState(0);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const [allImagesFetched, setAllImagesFetched] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  const [showScrollArrow, setShowScrollArrow] = useState(false);
  const [isGptActive, setIsGptActive] = useState(false);
  const [isGptActivePopup, setIsGptActivePopup] = useState(false);
  const [showSlider, setShowSlider] = useState(false);
  const [dataNotFound, setDataNotFound] = useState(false);
  const [searchOptions, setSearchOptions] = useState({ valueString: "" });
  const [activeTab, setActiveTab] = useState("all");
  const [loadedImages, setLoadedImages] = useState({});
  const [isFavoriteImages, setIsFavoriteImages] = useState({});

  useEffect(() => {
    if (isPrivilagesDetails?.communityGallery === false) {
      setIsGptActive(true);
    }
  }, [isPrivilagesDetails]);

  const handleCloseAccessPopup = () => {
    setIsGptActivePopup(!isGptActivePopup);
  };

  const handleSubscription = () => {
    handleShowSubscription();
    setIsGptActivePopup(false);
  };

  const toggleVisibility = () => {
    if (window.pageYOffset > 100) {
      setShowScrollArrow(true);
    } else {
      setShowScrollArrow(false);
    }
  };

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const handleLoad = () => {
    setImageLoader(false);
  };

  const handlePopup = (image, index) => {
    if (isPrivilagesDetails?.communityGallery === false) {
      setIsGptActivePopup(true);
    } else {
      setImageLoader(true);
      setShowPopup(true);
      setIsCurrentIndex(index);
      const createdDate = new Date(image?.created);
      const day = String(createdDate.getDate()).padStart(2, "0");
      const month = String(createdDate.getMonth() + 1).padStart(2, "0");
      const year = createdDate.getFullYear();
      const hours = String(createdDate.getHours()).padStart(2, "0");
      const minutes = String(createdDate.getMinutes()).padStart(2, "0");
      const formattedDate = `${day}.${month}.${year} - ${hours}:${minutes}`;

      setPopupData({
        ...image,
        created: formattedDate,
      });
      document.body.classList.add("popup-open");
    }
  };

  useEffect(() => {
    if (isCurrentIndex >= 0 && isCurrentIndex < isGalleryImages.length) {
      const currentImage = isGalleryImages[isCurrentIndex];
      const imageElement = new Image();
      imageElement.src = currentImage.baseImage || currentImage.url;

      imageElement.onload = () => {
        setImageLoader(false);
      };

      imageElement.onerror = () => {
        setImageLoader(false);
      };
    }
  }, [isCurrentIndex]);

  const handleNext = () => {
    setImageLoader(true);
    if (isCurrentIndex < isGalleryImages?.length - 1) {
      const nextIndex = isCurrentIndex + 1;
      const nextImage = isGalleryImages[nextIndex];
      const createdDate = new Date(nextImage?.created);
      const day = String(createdDate.getDate()).padStart(2, "0");
      const month = String(createdDate.getMonth() + 1).padStart(2, "0");
      const year = createdDate.getFullYear();
      const hours = String(createdDate.getHours()).padStart(2, "0");
      const minutes = String(createdDate.getMinutes()).padStart(2, "0");
      const formattedDate = `${day}.${month}.${year} - ${hours}:${minutes}`;

      setPopupData({
        ...nextImage,
        created: formattedDate,
      });

      setIsCurrentIndex(nextIndex);
    }
  };

  const handlePrev = () => {
    setImageLoader(true);
    if (isCurrentIndex < isGalleryImages?.length - 1) {
      const nextIndex = isCurrentIndex - 1;
      const nextImage = isGalleryImages[nextIndex];
      const createdDate = new Date(nextImage?.created);
      const day = String(createdDate.getDate()).padStart(2, "0");
      const month = String(createdDate.getMonth() + 1).padStart(2, "0");
      const year = createdDate.getFullYear();
      const hours = String(createdDate.getHours()).padStart(2, "0");
      const minutes = String(createdDate.getMinutes()).padStart(2, "0");
      const formattedDate = `${day}.${month}.${year} - ${hours}:${minutes}`;

      setPopupData({
        ...nextImage,
        created: formattedDate,
      });

      setIsCurrentIndex(nextIndex);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        if (isCurrentIndex < isGalleryImages?.length - 1) {
          if (isCurrentIndex !== 0) {
            setImageLoader(true);
            const nextIndex = isCurrentIndex - 1;
            const nextImage = isGalleryImages[nextIndex];
            const createdDate = new Date(nextImage?.created);
            const day = String(createdDate.getDate()).padStart(2, "0");
            const month = String(createdDate.getMonth() + 1).padStart(2, "0");
            const year = createdDate.getFullYear();
            const hours = String(createdDate.getHours()).padStart(2, "0");
            const minutes = String(createdDate.getMinutes()).padStart(2, "0");
            const formattedDate = `${day}.${month}.${year} - ${hours}:${minutes}`;

            setPopupData({
              ...nextImage,
              created: formattedDate,
            });

            setIsCurrentIndex(nextIndex);
          }
        }
      } else if (event.key === "ArrowRight") {
        if (isCurrentIndex < isGalleryImages.length - 1) {
          if (isCurrentIndex !== isGalleryImages.length - 1) {
            setImageLoader(true);
            const nextIndex = isCurrentIndex + 1;
            const nextImage = isGalleryImages[nextIndex];
            const createdDate = new Date(nextImage?.created);
            const day = String(createdDate.getDate()).padStart(2, "0");
            const month = String(createdDate.getMonth() + 1).padStart(2, "0");
            const year = createdDate.getFullYear();
            const hours = String(createdDate.getHours()).padStart(2, "0");
            const minutes = String(createdDate.getMinutes()).padStart(2, "0");
            const formattedDate = `${day}.${month}.${year} - ${hours}:${minutes}`;

            setPopupData({
              ...nextImage,
              created: formattedDate,
            });

            setIsCurrentIndex(nextIndex);
          }
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isGalleryImages, isCurrentIndex]);

  const handlePopupclose = () => {
    setShowPopup(false);
    document.body.classList.remove("popup-open");
  };

  useEffect(() => {
    setIsGalleryImages([]);
    setDataNotFound(true);
    setTimeout(() => {
      const delay = isGalleryImages?.length !== 0 ? 10 : 0;
      let timeoutId;

      const debounceSearch = () => {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
          setAllImagesFetched(false);
          galleryApiRef.current = false;
          getAllCommunityGalleryImages();
        }, delay);
      };

      debounceSearch();

      return () => {
        clearTimeout(timeoutId);
      };
    }, 0);
  }, [searchOptions, activeTab, isTeamChanges]);

  const getAllCommunityGalleryImages = () => {
    // if (!allImagesFetched) {
    if (!galleryApiRef.current) {
      galleryApiRef.current = true;
      setDataNotFound(true);
      let searchData = searchOptions;
      if (activeTab !== "all" && activeTab !== "favorite") {
        const currentValues = searchOptions?.valueString?.split(",");
        if (!currentValues?.includes("upscale")) {
          if (currentValues[0] !== "") {
            const newValues = [...currentValues, "upscale"];
            searchData = { valueString: newValues?.join(",") };
          } else {
            const newValues = ["upscale"];
            searchData = { valueString: newValues?.join(",") };
          }
        }
      }
      const data = localStorage.getItem("language");
      const payload = {
        pageIndex: 0,
        pageSize: isPageSize,
        searchString: searchData?.valueString,
        chinese: data === "ch" ? true : false,
        isFavorite: activeTab === "favorite" ? true : false,
      };
      if (payload) {
        setIsGalleryImages([]);
        GetAllImagesCommunityGalleryMongo((res) => {
          if (res.status === 200) {
            setDataNotFound(false);
            const newImages = res?.projectMedias || [];
            if (newImages.length === 0) {
              setAllImagesFetched(true);
            }
            setIsGalleryImages(newImages);
          } else {
            setDataNotFound(false);
          }
          if (res?.message === "Invalid access token") {
            updateSocketData({
              isExitPopup: true,
            });
          }
        }, payload);
      }
    }
    // }
  };

  const checkAllImagesLoaded = useCallback(() => {
    const images = document.querySelectorAll(".column-img");
    for (const image of images) {
      if (!image?.complete) {
        return false;
      }
    }
    return true;
  }, []);

  useEffect(() => {
    let isLoading = false;

    const getAllCommunityGalleryImages1 = () => {
      if (!allImagesFetched && !isLoading) {
        isLoading = true;

        const data = localStorage.getItem("language");
        const teamId = 0;
        let searchData = searchOptions;
        if (activeTab !== "all" && activeTab !== "favorite") {
          const currentValues = searchOptions?.valueString?.split(",");
          if (!currentValues?.includes("upscale")) {
            if (currentValues[0] !== "") {
              const newValues = [...currentValues, "upscale"];
              searchData = { valueString: newValues?.join(",") };
            } else {
              const newValues = ["upscale"];
              searchData = { valueString: newValues?.join(",") };
            }
          }
        }
        const payload = {
          pageIndex: isPageIndex,
          pageSize: isPageSize,
          searchString: searchData?.valueString,
          chinese: data === "ch" ? true : false,
          isFavorite: activeTab === "favorite" ? true : false,
        };
        if (payload) {
          GetAllImagesCommunityGalleryMongo((res) => {
            const newImages = res?.projectMedias || [];
            if (newImages.length === 0) {
              setAllImagesFetched(true);
            }
            setIsGalleryNewImages(res?.projectMedias);
            isLoading = false;
            setIsPageIndex((prevIndex) => prevIndex + 1);
            if (res?.message === "Invalid access token") {
              updateSocketData({
                isExitPopup: true,
              });
            }
          }, payload);
        }
      }
    };

    const handleScroll = () => {
      const isNearBottom =
        window.innerHeight + window.scrollY >= document.body.scrollHeight - 100;

      if (isNearBottom && !isLoading) {
        getAllCommunityGalleryImages1();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [
    isPageIndex,
    isPageSize,
    searchInput,
    allImagesFetched,
    checkAllImagesLoaded,
  ]);

  useEffect(() => {
    const uniqueGalleryImagesIds = new Set(
      isGalleryImages?.map((image) => image?.id)
    );
    const uniqueImages = isGalleryNewImages?.filter((newImage) => {
      return !uniqueGalleryImagesIds.has(newImage?.id);
    });
    if (uniqueImages?.length > 0) {
      setIsGalleryImages((prevImages) => [...prevImages, ...uniqueImages]);
    }
    if (isGalleryImages?.length === 40 && isPageIndex === 0) {
      setIsPageIndex(1);
    }
  }, [isGalleryImages, isGalleryNewImages, isPageIndex]);

  useEffect(() => {
    const areAllImagesLoaded = checkAllImagesLoaded();
    setAllImagesLoaded(areAllImagesLoaded);
  }, [isGalleryImages, checkAllImagesLoaded]);

  useEffect(() => {
    const favoritesMap = {};

    isGalleryImages.forEach((media) => {
      favoritesMap[media.id] = media.isFavorite;
    });

    setIsFavoriteImages(favoritesMap);
  }, [isGalleryImages]);

  const handleImageLoad = (mediaId) => {
    setLoadedImages((prevLoadedImages) => ({
      ...prevLoadedImages,
      [mediaId]: true,
    }));
  };

  const numRows = 5;
  const imagesPerPage = numRows * Math.ceil(window.innerHeight / 100);
  const numCols = Math.ceil(isGalleryImages?.length / numRows);

  const rows = [];
  for (let row = 0; row < numRows; row++) {
    const cols = [];
    for (let col = 0; col < numCols; col++) {
      const index = col * numRows + row;
      if (index < isGalleryImages?.length) {
        const image = isGalleryImages[index];
        cols.push(
          <div key={image?.id} className="column">
            <div className="column-cont">
              <div
                className={`column-cont__sec ${
                  isFavoriteImages[image?.id] ? "favorite" : ""
                }`}
                onClick={() => handlePopup(image, index)}
              >
                <img
                  className="column-img"
                  src={image?.thumbnail || image?.url}
                  alt={image?.id}
                  onLoad={() => handleImageLoad(image.id)}
                />
                <div className="favorites">
                  <div
                    className="fav-icon"
                    style={{
                      display: loadedImages[image?.id] ? "block" : "none",
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <img src={fav2} className="fav" alt="favorite" />
                    <div className="tooltip-show">
                      {!responsiveData && (
                        <FavoriteTooltip text={t("favorite")} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        cols.push(<div key={col} className="column"></div>);
      }
    }
    rows.push(
      <div key={row} className="row">
        {cols}
      </div>
    );
  }

  const [originalImage, setOriginalImage] = useState(new Image());
  const [originalPrevImage, setOriginalPrevImage] = useState(new Image());
  const [originalNextImage, setOriginalNextImage] = useState(new Image());
  const [alternateImage, setAlternateImage] = useState(new Image());

  useEffect(() => {
    originalImage.src = popupData?.url;
    if (isCurrentIndex !== 0) {
      originalPrevImage.src = isGalleryImages[isCurrentIndex - 1]?.url;
    }
    if (isGalleryImages?.length !== isCurrentIndex + 1) {
      originalNextImage.src = isGalleryImages[isCurrentIndex + 1]?.url;
    }

    if (popupData?.baseImage !== null) {
      alternateImage.src = popupData?.baseImage;
    }
  }, [popupData, isCurrentIndex]);

  const dynamicValue1 = popupData?.toolset?.split(",")[0]?.toUpperCase();

  const dynamicValue2 = popupData?.toolset?.split(",")[1]?.toUpperCase();

  useEffect(() => {
    setShowSlider(false);
  }, [popupData]);

  const handleShowSlider = () => {
    setShowSlider(true);
  };

  return (
    <UserPortalLayout>
      <div className="cgallery">
        <SideNavComponent />
        <div className="cgallery__container">
          <div className="cgallery__sections">
            <div className="cgallery__main">
              <div className="cgallery__main__section">
                <GalleryHeader
                  searchOptions={searchOptions}
                  setSearchOptions={setSearchOptions}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  isFavoriteTab={true}
                  isShowIcons={false}
                  isLoading={dataNotFound}
                />
                <div
                  className="cgallery__main__images__container"
                  data-scrollable="true"
                >
                  {isGalleryImages?.length !== 0 ? (
                    rows?.map((row, rowIndex) => (
                      <div key={`row-all-${rowIndex}`} className="row">
                        {row?.props?.children?.map((col, colIndex) => (
                          <div key={`col-all-${rowIndex}-${colIndex}`}>
                            {col}
                          </div>
                        ))}
                      </div>
                    ))
                  ) : (
                    <div className="gif-container">
                      {dataNotFound ? (
                        <img src={gif} alt="gif-loader" />
                      ) : (
                        <p>{t("no_data_found")}</p>
                      )}
                    </div>
                  )}
                </div>
                {showScrollArrow && (
                  <div className="gallery__main__section__scroll">
                    <img
                      src={downarrow}
                      alt="down-arrow"
                      onClick={handleClick}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {showPopup && (
          <WorkSpaceFeedPopUp
            popupData={popupData}
            isCurrentIndex={isCurrentIndex}
            handlePopupPrev={handlePrev}
            handlePopupNext={handleNext}
            handlePopupclose={handlePopupclose}
            handlePopupShowSlider={handleShowSlider}
            popupShowSlider={showSlider}
            popupImageLoader={imageLoader}
            popupDynamicValue1={dynamicValue1}
            popupDynamicValue2={dynamicValue2}
            showStealth={false}
            handlePopupLoad={handleLoad}
            isGalleryImages={isGalleryImages}
            showAllIcons={false}
          />
        )}
        {isGptActive === true && (
          <>
            {isGptActivePopup === true && (
              <PLanEnd
                handleCloseAccessPopup={handleCloseAccessPopup}
                handleSubscription={handleSubscription}
              />
            )}
          </>
        )}
      </div>
    </UserPortalLayout>
  );
};

export default CommunityGallery;
