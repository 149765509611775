import React from "react";
import "./Contact.scss";
import qr from "../../assets/images/contact.png";
import { t } from "i18next";
import SideNavComponent from "../../components/SideNav/SideNavComponent";
import UserPortalLayout from "../../components/UserPortalLayout/UserPortalLayout";
import police from "../../assets/images/icons/police-logo.png";

const ContactComponent = () => {
  return (
    <UserPortalLayout>
      <div className="contact-airi">
        <SideNavComponent />
        <div className="contact-airi__container">
          <div className="contact-airi__sections">
            <div className="contact-airi__left">
              <img src={qr} alt="contact-qr" />
              <p>{t("contact_content2")}</p>
            </div>
            <div className="contact-airi__right">
              <div className="contact-airi__right__content">
                <h2>{t("contact_content")}</h2>
                <div className="contact-airi__right__sec">
                  <h6>{t("contact_mail")}</h6>
                  <a
                    href="mailto:contactairilab@163.com"
                    rel="noreferrer"
                    target="_blank"
                  >
                    contactairilab@163.com
                  </a>
                </div>
                <div className="contact-airi__right__sec">
                  <h6>{t("contact_phone")}</h6>
                  <a href="tel:+86-755-26930800">+86-181 6573 7937</a>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-airi__bottom">
            <div className="contact-airi__bottom__section">
              <p>{t("footer_bottom_sec1")}</p>
              <p>{t("footer_bottom_sec2")}</p>
            </div>
            <div className="contact-airi__bottom__section">
              <a
                href="https://beian.miit.gov.cn/"
                target="_blank"
                rel="noreferrer"
              >
                {t("footer_bottom_sec3")}
              </a>
              <a
                href="https://beian.mps.gov.cn/#/query/webSearch?code=44030002003609"
                target="_blank"
                rel="noreferrer"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <img src={police} style={{ width: '15px', height: '15px', marginRight: '2px' }} />
                {t("footer_bottom_sec4")}
              </a>
            </div>
            <div className="contact-airi__bottom__section">
              <p>{t("footer_bottom_sec0")}</p>
            </div>
          </div>
        </div>
      </div>
    </UserPortalLayout>
  );
};

export default ContactComponent;
