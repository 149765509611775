import React from "react";

const CommunityGalleryIcon = ({fill, size = "22"}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7981_102888)">
        <path
          d="M20.389 8.63458C19.097 6.53036 16.1581 3 11 3C5.84191 3 2.903 6.53036 1.61098 8.63458C1.21157 9.28059 1 10.0251 1 10.7846C1 11.5441 1.21157 12.2886 1.61098 12.9347C2.903 15.0389 5.84191 18.5692 11 18.5692C16.1581 18.5692 19.097 15.0389 20.389 12.9347C20.7884 12.2886 21 11.5441 21 10.7846C21 10.0251 20.7884 9.28059 20.389 8.63458ZM18.9687 12.0625C17.8591 13.8668 15.3476 16.9032 11 16.9032C6.65244 16.9032 4.14087 13.8668 3.03129 12.0625C2.79399 11.6785 2.6683 11.236 2.6683 10.7846C2.6683 10.3332 2.79399 9.89074 3.03129 9.50675C4.14087 7.70242 6.65244 4.66605 11 4.66605C15.3476 4.66605 17.8591 7.69909 18.9687 9.50675C19.206 9.89074 19.3317 10.3332 19.3317 10.7846C19.3317 11.236 19.206 11.6785 18.9687 12.0625Z"
          fill={fill}
        />
        <path
          d="M11.0011 6.61719C10.1773 6.61719 9.372 6.86147 8.68704 7.31914C8.00209 7.77681 7.46824 8.42731 7.15299 9.18839C6.83774 9.94946 6.75526 10.7869 6.91597 11.5949C7.07668 12.4028 7.47337 13.145 8.05588 13.7275C8.63838 14.31 9.38053 14.7067 10.1885 14.8674C10.9964 15.0281 11.8339 14.9456 12.595 14.6304C13.3561 14.3151 14.0066 13.7813 14.4642 13.0963C14.9219 12.4114 15.1662 11.6061 15.1662 10.7823C15.1649 9.67806 14.7256 8.61941 13.9448 7.83858C13.164 7.05776 12.1053 6.61851 11.0011 6.61719ZM11.0011 13.2814C10.5068 13.2814 10.0236 13.1348 9.61265 12.8602C9.20168 12.5856 8.88137 12.1953 8.69222 11.7387C8.50307 11.282 8.45358 10.7795 8.55001 10.2948C8.64643 9.80999 8.88445 9.3647 9.23395 9.0152C9.58345 8.6657 10.0287 8.42768 10.5135 8.33126C10.9983 8.23483 11.5008 8.28432 11.9574 8.47347C12.4141 8.66262 12.8044 8.98293 13.079 9.3939C13.3536 9.80487 13.5001 10.288 13.5001 10.7823C13.5001 11.4451 13.2368 12.0808 12.7682 12.5494C12.2995 13.0181 11.6639 13.2814 11.0011 13.2814Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_7981_102888">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CommunityGalleryIcon;
